<template>
    <nav class="sticky" id="top_nav">
        <div class="flx jc-sb ai-c nav-wrapper">
            <div class="flx">
                <button class="hamburger-menu" @click.prevent="$store.commit('toggleMenu')" aria-expanded="false" aria-controls="menus" v-if="device == 'mobile'" id="mobile_menu_toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 25.143 19.533">
                        <path d="M2,18.234H24m-22-8.2H24M2,1.844H14.571" transform="translate(-0.429 -0.272)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="3.143"/>
                    </svg>
                </button>
                <div v-else>
                    <div>Hello <strong>Climber!</strong></div>
                </div>
            </div>
            <div class="flx">
                <div class="toggle-container" v-if="device == 'desktop' || device == 'tablet'">
                    <div class="toggle-button">
                        <div class="toggle-option active">
                            <router-link :to="{ name: 'ViewEvents' }">
                                Home
                            </router-link>
                        </div>
                        <div class="toggle-option"><a href="https://climbonsight.ca/marketplace/">Marketplace</a></div>
                    </div>
                </div>
               
            </div>
            <div class="flx gap-70">
                <ul class="flx gap-16 ai-c">
                    <a href="https://climbonsight.ca/">
                        <img class="eventslogo" :src="viewEventsLogo" width="100px" alt="climbonsight">
                    </a>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import userNameMixin from '@/mixins/userNameMixin'
import { mapState } from 'vuex'
export default {
    name: 'TopNavBarEvents',
    components: {  },
    props: {
        device: String,
        user: Object,
        notification_count: Number,
        token: String
    },
    mixins: [userNameMixin],
    computed: {
        ...mapState({
            pageLoader: (state) => state.pageLoader,
            viewEventsLogo: (state) => state.data.viewEventsLogo,
        })
    }
}
</script>

<style lang="scss" scoped>
.eventslogo{
    width: 200px!important;
    aspect-ratio: auto!important;
    background-color: #fcf7f3!important;
}
nav {
    container-type: inline-size;
    top: 0;
    padding: 16px 0 14px 0;
    z-index: 90;
    background-color: var(--bg-color);
    background-color: rgba(251, 247, 244, 0.7);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
}
.nav-wrapper{
    height: 60px;
}
button.hamburger-menu {
    padding: 16px;
    background-color: transparent;
    margin-left: -16px;
    &:active {   
        background-color: var(--input-hover);
    }
}
@container (inline-size <= 548px) {
    span {
        display: none
    }
    .gap-50{
        gap: 30px
    }
}

.rotate {
    animation: spin 1s infinite linear;
}
@keyframes spin{ 
  100%{transform: rotate(1turn)}
}

/*menu */
.toggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.toggle-button {
    display: flex;
    border: 1px solid #eee;
    border-radius: 30px;
    overflow: hidden;
    background-color: #ffffff;
    padding: 5px;
}

.toggle-option {
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.toggle-option.active {
    background-color: #fcf7f3;
    border-radius: 30px;
}

.toggle-option:not(.active):hover {
    background-color: #fcf7f3;
    border-radius: 30px;
    overflow: hidden;
}

</style>
