<template>
    <button v-if="$route.query.origin !== 'ExploreEvents' && $route.query.status" @click="$emit('booking-trigger')" class="button-primary btn-md" :class="{ 'button-disabled2' : eventStatus && (!eventStatus.accepted || eventStatus.paid) }" :disabled="eventStatus && (!eventStatus.accepted || eventStatus.paid) ? true : false">
        <span>Complete booking</span>
    </button>
    <button v-else-if="$route.path == '/viewevents'" class="button-primary btn-md" @click.prevent="doClicks('Event Booking','To book this event you have to be logged in or signed up.')" id="openModal">
        <span>Book Event</span>
    </button>
    <button v-else class="button-primary btn-md" @click="$emit('booking-trigger')">
        <span>Book event</span>
    </button>
</template>
<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
    name: 'BookingTriggerButton',
    computed: {
        ...mapGetters(['getDevice']),
        ...mapState({
        }),
    },
    props: {
        eventStatus: Object,
        is_climber: Boolean,
    },
    methods: {
        ...mapMutations(['setModalTitle', 'setModalContent']),
        doClicks(title,content) {
            this.setModalTitle(title);
            this.setModalContent(content);
            this.$store.commit('openLoginModal', 'open_login')
        }
    }
}
</script>
<style scoped>
button {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}
</style>
