<template>
  <div class="flx column gap-8">
    <div class="flx br-16 gap-4 ai-c bg-white custom-donation">

      <textarea @input="formData" v-model="thisForm.donations_title" class="form-control2 br-24 h-100 donation_title"
        wrap="soft" placeholder="Enter Donation Title" :class="input2 ? 'form-control2' : 'form-control'" :name="'donations_title' + index"></textarea>

      <input @input="formData" v-model="thisForm.donations_price" type="number"
        class="form-control2 br-24 h-100 donations_price" min="1" placeholder="Enter price"
        :class="input2 ? 'form-control2' : 'form-control'" :name="'donations_price' + index">
    </div>

    <div class="flx jc-fe">
      <a @click="$emit('donations-remove-row', index)" class="a-link flx gap-4 ai-c">
        <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18">
          <path
            d="M-1981,18a9.01,9.01,0,0,1-9-9,9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-1981,18Zm-4.325-9.7a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h8.331a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7Z"
            transform="translate(1990)" fill="#d81d1d" />
        </svg>
        Remove
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DonationsInput',
  props: {
    form: Object,
    index: Number,
    donations_length: Number,
    input2: Boolean
  },
  data() {
    return {
      thisForm: {
        id: this.form.id,
        donations_title: this.form.donations_title,
        donations_price: this.form.donations_price
      }
    }
  },
  methods: {
    formData() {
      this.$emit('donations-add-form-input', this.thisForm)
    }
  }
}
</script>

<style lang="scss" scoped>
.input-wrapper {

  select,
  input,
  textarea {
    padding: 10px 20px;
  }
}

/* Customize the thumb of the range inputs */
.custom-donation {
  padding: 11px;
  height: 55px;

  input[type="number"] {
    background-color: #ECECEC;
    width: 30% !important;
    border: 1px solid transparent !important;
    padding: 4px 8px;

    &::placeholder {
      font-size: 0.9rem;
    }

    &:hover {
      border-color: #000000 !important;
    }
  }

  textarea {
    background-color: rgb(255, 255, 255);
    width: 70% !important;
    border: 0.2px solid black !important;
    padding: 4px 8px;
    word-wrap: break-word;
    /* enable word wrapping */
    white-space: normal;
    /* enable wrapping */
    overflow-wrap: break-word;
    /* enable wrapping */
    word-break: break-all;

    &::placeholder {
      font-size: 0.9rem;
    }

    &:hover {
      border-color: #000000 !important;
    }
  }

  input[type="text"] {
    background-color: rgb(255, 255, 255);
    width: 70% !important;
    // border: 0.2px solid black !important;
    padding: 4px 8px;
    word-wrap: break-word;
    /* enable word wrapping */
    white-space: normal;
    /* enable wrapping */
    overflow-wrap: break-word;
    /* enable wrapping */
    word-break: break-all;

    &::placeholder {
      font-size: 0.9rem;
    }

    &:focus {
      border: 0;
      background-color: transparent;
    }

    &:hover {
      border: 0;
      background-color: transparent;
    }

    &:visited {
      border: 0;
      background-color: transparent;
    }

    :autofill {
      border: 0;
      background-color: red;
    }
  }
}
</style>