<template>
    <teleport v-if="logingOut" to="body">
        <div class="overlay centered">
            <spinner :size="30" :color="'#fff'" />
        </div>
    </teleport>
    <aside v-if="device == 'mobile'" id="menus" class="flx" :class="{ 'expanded' : menu }">
        <nav class="flx column gap-32 jc-sb">
            
            <div v-if="device == 'mobile'" class="flx column gap-40">
                <div class="flx jc-sb top-btn-wrapper">
                    <button @click="$store.commit('toggleMenu')" class="br-50 bg-transparent">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 20.828 20.828">
                            <path d="M19,19l-9-9m0,0L1,1m9,9,9-9m-9,9L1,19" transform="translate(0.414 0.414)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                        </svg>
                    </button>
                    <div class="flx gap-8">
                        
                       
                    </div>
                </div>
                
            </div>
            <div class="flx column nav-wrapper" @mouseup="device == 'mobile' ? $store.commit('toggleMenu') : ''">
                <li>
                    <router-link :to="{ name: 'Home' }">
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 22.664 22.664">
                            <path d="M-1981.058,22.6a.469.469,0,0,1-.443-.47V15.216a.945.945,0,0,1,.944-.946h3.777a.945.945,0,0,1,.944.946v6.915a.469.469,0,0,1-.443.47q-1.193.063-2.376.063Q-1979.858,22.664-1981.058,22.6Zm7.112-.7V15.216a2.836,2.836,0,0,0-2.833-2.839h-3.777a2.835,2.835,0,0,0-2.832,2.839v6.673a.469.469,0,0,1-.521.468c-.18-.021-.361-.043-.541-.066-2.93-.374-4.319-1.5-4.855-3.943a29.608,29.608,0,0,1-.485-9.857c.159-1.057,5.785-5.379,8.988-7.764a3.652,3.652,0,0,1,4.36-.01c3.23,2.383,8.911,6.718,8.981,7.774,0,.033,0,.068.006.1a37.322,37.322,0,0,1-.492,9.753c-.485,2.464-1.923,3.577-4.855,3.943q-.313.039-.625.074a.469.469,0,0,1-.053,0A.47.47,0,0,1-1973.947,21.9Z" transform="translate(1990)" fill="#b5a28f"/>
                        </svg>
                        <span>Home</span>
                    </router-link>
                </li>
                <li>
                    <a href="https://climbonsight.ca/marketplace/" >
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 23 23">
                            <path d="M11.5,23C2.03,23,0,20.97,0,11.5S2.03,0,11.5,0,23,2.03,23,11.5,20.97,23,11.5,23ZM6.641,13.77a.958.958,0,0,1-1.782-.706l.891.353C6.641,13.769,6.641,13.77,6.641,13.77Zm-.891-.353.891.352,0-.01.019-.045c.017-.041.044-.1.08-.182.071-.158.178-.382.314-.636A7.179,7.179,0,0,1,8.15,11.33a1.512,1.512,0,0,1,1.082-.6c.243.016.759.2,1.462,1.286a4.012,4.012,0,0,0,2.947,2.158,3.364,3.364,0,0,0,2.581-1.171,9.055,9.055,0,0,0,1.409-2c.161-.3.286-.563.372-.753.043-.1.076-.172.1-.227l.027-.065.008-.019,0-.006v0s0,0-.891-.354l.891.353a.958.958,0,0,0-1.782-.706h0l0,.01-.01.023-.009.022c-.017.041-.044.1-.08.182-.072.158-.178.382-.314.636A7.18,7.18,0,0,1,14.85,11.67a1.512,1.512,0,0,1-1.082.6c-.243-.016-.759-.2-1.462-1.286A4.012,4.012,0,0,0,9.358,8.822,3.364,3.364,0,0,0,6.778,9.993a9.055,9.055,0,0,0-1.409,2c-.161.3-.286.563-.372.753-.043.1-.076.172-.1.227l-.027.065-.008.019,0,.006v0S4.859,13.064,5.75,13.417Z" fill="#b5a28f" fill-rule="evenodd"/>
                        </svg>
                        <span>Marketplace</span>
                    </a>
                </li>
                
            </div>
            <li>
                
            </li>
        </nav>
    </aside>
</template>

<script>
import userRolesMixin from '@/mixins/userRolesMixin';
import { mapState } from 'vuex';
import Spinner from './Spinner.vue';
export default {
    components: { Spinner },
    name: 'MainMenuEvents',
    mixins: [userRolesMixin],
    props: {
        user: Object,
        device: String
    },
    computed: {
        ...mapState({
            menu: (state) => state.menu,
            s3bucket: (state) => state.s3bucket,
            default_avatar: (state) => state.data.default_avatar
        }),
        computedColor() {
            return this.user.color
        }
    },
    data() {
        return {
            logingOut: false
        }
    },
    methods: {
        logOut() {
            this.logingOut = true
            this.$store.dispatch('logOut')
        }
    }
}
</script>

<style lang="scss" scoped>
$navWidth: 185px;
$colapseGap: 12px;
aside{
    background-color: var(--secondary-light);
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 92;
    *{
        transition: 0.3s ease-out width;
    }
    overflow-x: auto;
    &::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none; 
        scrollbar-width: none; 
    }
    a {
        position: relative;
        display: flex;
        &:hover:not(.router-link-active, .u-a){
            background-color: rgba($color: #ffffff, $alpha: .1);
        }
        &:active:not(.router-link-active, .u-a) {
            background-color: rgba($color: #ffffff, $alpha: .08) ;
            transform: scale(0.98);
        }
        i{
            background-color: var(--primary-color);
            font-size: 0.8rem;
            border-radius: 16px;
            font-weight: 600;
            font-style: normal;
        }
        div {
            background-color: #212020;
            height: 40px;
            width: 40px;
        }
    }
}
.tablet aside, .desktop aside {
    padding: 32px var(--aside-padding);
    left: 0;
    margin: var(--dash-padding);
    border-radius: 40px;
    height: calc(100vh - var(--dash-padding) *2);
    #icon {
        display: block;
        margin-top: 5px;
    }
    #logo_wrapper{
        display: flex;
    }
    nav{
        height: 100%;
        width: var(--nav-width);
        color: var(--ft-white);
        *{
            transition: inherit;
        }
        .nav-wrapper{
            gap: $colapseGap;
            svg {
                margin-left: 8px;
            }
        }
        li {
            display: flex;
            &:hover {
                a {
                    background-color: #3721040c;
                }
            }
        }
        #logo{
            display: none;
        }
        a{
            flex-grow: 1;
            width: inherit;
            height: 45px;
            border-radius: 16px;
            align-items: center;
            transition: 100ms background-color linear;
            span {
                display: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 15px;
                animation: fadeOutSlide .6s;
            }
            i{
                position: absolute;
                right: 8px;
                top: 8px;
                height: 9px;
                width: 9px;
                overflow: hidden;
                border: 2px solid #000;
            }
        }
    }
    &:hover {
        border-radius: 24px;
        nav {
            width: $navWidth;
            gap: 16px;
            a {
                justify-content: flex-start;
                gap: 12px;
                span {
                    display: block;
                    animation: fadeInSlide 0.6s;
                }
                i{
                    position: unset;
                    padding: 0 8px;
                    height: unset;
                    width: unset;
                    border: unset;
                }
            }
            #logo {
                display: block;
            }
            #icon {
                display: none;
            }
        }
    }
}
.mobile {
    --aside-padding: 20px;
    aside {
        padding: 16px var(--aside-padding);
        left: calc((-100vw - 120px));
        width: 100vw;
        min-width: 270px;
        height: 100dvh;
        transition: 0.3s ease-out;
        visibility: hidden;
        background-color: #000;
        .nav-wrapper{
            gap: 2px;
            path {
                fill: #c1c1c4;
            }
        }
        #icon{
            display: none;
        }
        nav {
            gap: 16px;
            width: 100%;
            a {
                border-radius: 16px;
                gap: 16px;
                height: 60px;
                padding: 0 16px;
                align-items: center;
                color: #c1c1c4;
                i{
                    padding: 0 8px;
                }
            }
            span {
                transform: translateX(-50px);
                transition: 0.6s ease;
            }
            
            .logout{
                margin-top: 20px;
            }
            #logo_wrapper{
                padding: 10px 16px;
                svg {
                    height: 35px;
                }
            }
        }
    }
    .expanded{
        left: 0;
        visibility: visible;
        span {
            transform: translateX(0);
        }
    }
}
.top-btn-wrapper{
    button {
        height: 50px;
        width: 50px;
    }
}
@keyframes fadeInSlide {
  from { opacity: 0; transform: translateX(10px);}
  to { opacity: 1; display: block; transform: translateX(0)}
}
@keyframes fadeOutSlide {
  from { display: block; transform: translateX(0) }
  to { display: none; transform: translateX(60px)}
}
.desktop, .tablet {
    .router-link-active{
        path {
            fill: #000;
        }
        i {
            background-color: var(--white);
            color: var(--primary-color);
            border-color: var(--primary-color);
        }
        // span {
        //     font-weight: 600;
        // }
    }
}
.mobile {
    .router-link-active{
        &::before {
            content: '';
            width: 4px;
            background-color: var(--secondary-color);
            position: absolute;
            height: 50%;
            border-radius: 2px;
            left: -8px;
        }
    }
}
img{
    width: 75px;
    height: 75px;
    transition: 0.3s ease;
}
.overlay{
    --opacity: 0.7;
    background-color: rgba($color: #000000, $alpha: var(--opacity));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}
img.custom-color {
    background-color: v-bind(computedColor);
}
</style>
