<template>
    <div class="flx jc-sb br-16 ai-c bg-white custom-price">
        <span>{{ label }} person{{ label > 1 ? 's' : '' }}</span>
        <input v-model="thisForm.price" @input="handleInput" type="number" class="form-control2 br-24 h-100" min="1"  placeholder="Enter price" >
    </div>
</template>

<script>
export default {
    name: 'PriceInput',
    props: {
        index: Number,
        label:Number,
        prices: Array
    },
    computed: {
        computedPrice() {
            if(this.prices && this.prices.length) {
                const priceObj = this.prices.find(data => data.id === this.index)
                if(priceObj)
                return priceObj.price
                else
                return ''
            }
            else
            return ''
        }
    },
    data() {
        return {
            thisForm: {
                id: this.index,
                label: '',
                price: ''
            }
        }
    },
    created() {
        this.thisForm.label = this.label + ' person' + (this.label > 1 ? 's' : '');
    },
    watch: {
        label(newVal) {
            this.thisForm.label = newVal+' person'+(newVal > 1 ? 's' : '');
        },
        computedPrice(newVal) {
            this.thisForm.price = newVal
        }
    },
    methods: {
        handleInput() {
            this.$emit('add-price-input', this.thisForm)
        }
    },
    mounted() {
        this.thisForm.price = this.computedPrice
    }
}
</script>

<style lang="scss" scoped>
.custom-price {
    padding: 11px;
    height: 55px;
    input {
        background-color: #ECECEC;
        max-width: 102px;
        border: 1px solid transparent !important;
        padding: 4px 8px;
        &::placeholder {
            font-size: 0.9rem;
        }
        &:hover {
            border-color: #000000 !important;
        }
    }
}
</style>