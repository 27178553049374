<template>
      <div class="flx column gap-8">
        <div class="input-wrapper">
          <input
            v-model="thisForm.address"
            @focusout="checkAddressInput"
            @change="formData"
            class="br-16 w-100"
            type="search"
            autocomplete="off"
            :ref="'address_'+index"
            :id="'address_'+index"
            :class="[{'error-border': validation.errors.addressInputs }, input2 ? 'form-control2' : 'form-control']" 
            placeholder="Enter location then pick from dropdown list"
          />
          <span class="input-error" v-if="validation.error && validation.errors.addressInputs">
                {{ validation.errors.addressInputs[0] }}
            </span>
        </div>
        <div class="flx jc-fe">
            <a @click="$emit('remove-row', index)" class="a-link flx gap-4 ai-c">
                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18">
                    <path  d="M-1981,18a9.01,9.01,0,0,1-9-9,9.01,9.01,0,0,1,9-9,9.01,9.01,0,0,1,9,9A9.01,9.01,0,0,1-1981,18Zm-4.325-9.7a.7.7,0,0,0-.7.7.7.7,0,0,0,.7.7h8.331a.7.7,0,0,0,.7-.7.7.7,0,0,0-.7-.7Z" transform="translate(1990)" fill="#d81d1d"/>
                </svg>
                Remove
            </a>
        </div>
      </div>
  </template>
  
  <script>
  import inputValidationMixin from '@/mixins/inputValidation'
  export default {
    mixins: [inputValidationMixin],
    name: 'AddressInput',
    props: {
        form: Object,
        index: Number,
        length: Number,
        input2: Boolean
    },
    data() {
      return {
          thisForm: {
                id: this.form.id,
                address: this.form.address,
                lng: '',
                lat:''
          }
      };
    },
    watch: {
      localAddress(newVal) {
        // Emit the change event when the input value changes
        this.$emit('update-address', newVal);
      }
    },
    methods: {
      formData() {
            this.$emit('add-form-input', this.thisForm)
      }
      ,checkAddressInput() {
            if(this.thisForm.address == '') {
                this.thisForm.address = ''
            }
        },
        getFullAddress(place) {
            if (!place.address_components) {
                return '';
            }

            // Construct the full address from the address components
            let address = place.address_components.map(component => component.long_name).join(', ');
            return address;
        }
    },
    mounted() {
      const google = window.google
        const autocompletenew = new google.maps.places.Autocomplete(
            this.$refs['address_'+this.index],
            {
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(5.564836107372255, -0.3288140182577681)
                )
            }
        );
        
        autocompletenew.addListener('place_changed', () => {
            let address = autocompletenew.getPlace();
            // Extract the full address from the address_components array
            let fullAddress = address.formatted_address || this.getFullAddress(address);


            this.thisForm.address = fullAddress
            this.thisForm.lat = address.geometry.location.lat()
            this.thisForm.lng = address.geometry.location.lng()
        })
    }
  
  };
  </script>
  
  <style scoped>
  .input-wrapper {
      select, input, textarea {
          padding: 10px 20px;
      }
  }
  </style>
  