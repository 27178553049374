<template>
    <div class="br-16 bg-white main-details-wrapper" :class="is_climber || is_super ? 'dynamic-padding' : 'details-padding'">
        <div class="flx jc-sb ai-c mb-16">
            <div class="flx gap-8 ai-c">
                <h3>Event details</h3>
                <div>
                    <booking-status v-if="is_climber" :status="event" />
                </div>
            </div>
            <div class="flx gap-8">
                <button @click="$store.commit('preSetTempData', { data: event, modal: 'event_edit'})" v-if="is_guide" class="button-primary btn-sm">Edit event</button>
                <button @click="$store.commit('setDeleteModal', {id: event.id, type: 'event'})"  class="button-danger btn-sm" v-if="is_guide">Delete</button>
            </div>
        </div>
        <div class="gap-24 flx col-row">
            <div class="flx gap-16 column w-50 flx-grow-1">
                <div class="relative gallery-button-trigger">
                    <img class="br-16 profile-img" :src="event.gallery && event.gallery.length ? s3bucket+'/'+ JSON.parse(event.gallery)[0] : ''" :alt="event.event_name">
                    <gallery-button :event="event" />
                </div>
                <div class="flx gap-8 jc-sb ai-c">
                    <div class="flx gap-8">
                        <h3>{{ event.event_name }}</h3>
                    </div>
                </div>
                <div class="flx jc-sb">
                    <div class="flx gap-8">
                        <div>
                            <event-type :eventType="event.event_type" />
                        </div>
                        <span class="fs-08 badge badge-cyan br-24 wrap-text wrap-line-1">{{ event.category }}</span>
                    </div>
                    <div v-if="!is_guide && event.guides_link">
                        <guides-link-button :link="event.guides_link" />
                    </div>
                </div>
                <div class="list-row-shading" v-if="event.event_description">
                    <div class="gray">Event description</div>
                    <div>
                        <div>
                            {{ !showMore && event.event_description.length > limit ? event.event_description.slice(0, limit) + '...' : event.event_description  }}
                            <span v-if="event.event_description.length > limit" @click="toggleShow" class="show-more">{{ showMore ? 'Read less' : 'Read more' }}</span>
                        </div>
                    </div>
                </div>
                <div class="flx gap-16 flx-wrap column">
                    <div v-if="event.receipt_no" class="list-row-shading">
                        <div class="gray">{{ event.paid ? 'Amount paid' : 'Amount'}}</div>
                        <div><strong>{{ formatAmount(Number(this.event.total_price))   }}</strong></div>
                    </div>
                    <div v-else class="list-row-shading">
                        <div class="gray">Event pricing</div>
                        <div v-if="(event.event_type === 'public' || event.event_type === 'private')  && isPriceMultiple" >
                            <div class="flx gap-8 pricelist_cover" v-if="event.event_duration === 'fhday'">
                                <span class="pricelist_text">Persons</span>
                                <span class="pricelist_amount">Full Day</span>
                                <span class="pricelist_amount">Half Day</span>
                            </div>
                            <div v-for="(prices, index) in computedPriceRangePublic" :key="index" :index="index"
                                class="flx gap-8 pricelist_cover">
                                <span class="pricelist_text">{{ prices.label }}</span>
                                <span class="pricelist_amount">CAD${{ prices.price }}</span>
                                <span class="pricelist_amount" v-if="prices.priceh">CAD${{ prices.priceh }}</span>
                            </div>
                        </div>
                        <div v-if="event.event_type === 'public' && !isPriceMultiple"><strong>CA${{ event.price }}</strong></div>
                    </div>
                    <div class="list-row-shading">
                        <div class="gray">Date</div>
                        <div v-if="event.receipt_no">
                            <span v-if="event.repeat_at">{{ format_date(event.date_selected) }} </span>
                            <span v-else>{{ format_date(event.start_date) }}</span>
                        </div>
                        <div v-else>
                            <div v-if="event.start_date !== event.end_date">
                                <i class="gray">From: </i><span>{{ format_date(event.start_date) }}</span><br />
                                <i class="gray">To: </i><span>{{ format_date(event.end_date) }}</span>
                            </div>
                            <div v-else>
                                <span>{{ format_date(event.start_date) }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="list-row-shading">
                        <div class="gray">Event duration</div>
                        <div v-for="duration in multiDuration" :key="duration">
                            <div v-if="duration === 'fhday'">Full Day / Half day</div>
                            <div v-else> {{ duration }}</div>
                        </div>
                        
                    </div>
                    <div v-if="is_guide" class="list-row-shading">
                        <div class="gray">Min attendance</div>
                        <div>{{ event.attendance_min }}</div>
                        <div class="gray">Max. attendance</div>
                        <div>{{ event.attendance_limit }}</div>
                    </div>
                    <div class="list-row-shading">
                        <div class="gray">Event location</div>
                        <div>
                            <a target="_blank" :href="computedAddressLink" class="flx gap-6 mt-4">
                                <svg width="20px" height="20px" style="min-width:20px!important;" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 8.49478C8.604 8.49478 9.5 7.59934 9.5 6.49606C9.5 5.39278 8.604 4.49738 7.5 4.49738C6.396 4.49738 5.5 5.39278 5.5 6.49606C5.5 7.59934 6.396 8.49478 7.5 8.49478Z" stroke="#000000" stroke-linecap="square"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 6.49606C13.5 11.4928 8.5 14.4909 7.5 14.4909C6.5 14.4909 1.5 11.4928 1.5 6.49606C1.5 3.18522 4.187 0.5 7.5 0.5C10.813 0.5 13.5 3.18522 13.5 6.49606Z" stroke="#000000" stroke-linecap="square"/>
                                </svg>
                                {{ event.address }}
                                <svg fill="#000000" width="20px" height="20px" style="min-width:20px!important;" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M 5.1367 28.2461 C 5.9569 29.0430 6.7773 29.3008 8.3945 29.3242 L 26.1367 29.3945 C 26.3007 29.3945 26.4413 29.4179 26.5116 29.4883 C 26.5820 29.5586 26.6054 29.6992 26.6054 29.8633 L 26.6757 47.6055 C 26.6991 49.2226 26.9569 50.0430 27.7773 50.8633 C 28.8789 51.9883 30.4257 51.8008 31.5741 50.6758 C 32.1835 50.0664 32.6757 49.0586 33.1210 48.1211 L 51.0509 9.4492 C 51.9884 7.4805 51.8712 6.0274 50.9103 5.0898 C 49.9724 4.1289 48.5194 4.0117 46.5507 4.9492 L 7.8789 22.8789 C 6.9413 23.3242 5.9335 23.8164 5.3241 24.4258 C 4.1991 25.5742 4.0116 27.1211 5.1367 28.2461 Z M 9.8945 25.7617 C 9.8710 25.6914 9.9179 25.6211 10.0585 25.5508 L 47.1601 8.5117 C 47.3476 8.4414 47.4413 8.4414 47.5116 8.4883 C 47.5585 8.5586 47.5585 8.6523 47.4882 8.8398 L 30.4491 45.9414 C 30.3789 46.0820 30.3085 46.1289 30.2382 46.1055 C 30.1913 46.1055 30.1210 46.0117 30.1210 45.9180 L 30.2382 27.4023 C 30.2382 26.8164 30.0273 26.4414 29.7929 26.2070 C 29.5585 25.9726 29.1835 25.7617 28.5976 25.7617 L 10.0820 25.8789 C 9.9882 25.8789 9.8945 25.8086 9.8945 25.7617 Z"/>
                                </svg>
                            </a>
                        </div>
                        <div v-if="computedOtherLocations.length">
                            <a target="_blank" class="flx gap-6 mt-4" :href="locations.link" v-for="(locations, index) in computedOtherLocations" :key="index" >
                                <svg width="20px" height="20px" style="min-width:20px!important;" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 8.49478C8.604 8.49478 9.5 7.59934 9.5 6.49606C9.5 5.39278 8.604 4.49738 7.5 4.49738C6.396 4.49738 5.5 5.39278 5.5 6.49606C5.5 7.59934 6.396 8.49478 7.5 8.49478Z" stroke="#000000" stroke-linecap="square"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5 6.49606C13.5 11.4928 8.5 14.4909 7.5 14.4909C6.5 14.4909 1.5 11.4928 1.5 6.49606C1.5 3.18522 4.187 0.5 7.5 0.5C10.813 0.5 13.5 3.18522 13.5 6.49606Z" stroke="#000000" stroke-linecap="square"/>
                                </svg>
                                {{ locations.address}}
                                <svg fill="#000000" width="20px" height="20px" style="min-width:20px!important;" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M 5.1367 28.2461 C 5.9569 29.0430 6.7773 29.3008 8.3945 29.3242 L 26.1367 29.3945 C 26.3007 29.3945 26.4413 29.4179 26.5116 29.4883 C 26.5820 29.5586 26.6054 29.6992 26.6054 29.8633 L 26.6757 47.6055 C 26.6991 49.2226 26.9569 50.0430 27.7773 50.8633 C 28.8789 51.9883 30.4257 51.8008 31.5741 50.6758 C 32.1835 50.0664 32.6757 49.0586 33.1210 48.1211 L 51.0509 9.4492 C 51.9884 7.4805 51.8712 6.0274 50.9103 5.0898 C 49.9724 4.1289 48.5194 4.0117 46.5507 4.9492 L 7.8789 22.8789 C 6.9413 23.3242 5.9335 23.8164 5.3241 24.4258 C 4.1991 25.5742 4.0116 27.1211 5.1367 28.2461 Z M 9.8945 25.7617 C 9.8710 25.6914 9.9179 25.6211 10.0585 25.5508 L 47.1601 8.5117 C 47.3476 8.4414 47.4413 8.4414 47.5116 8.4883 C 47.5585 8.5586 47.5585 8.6523 47.4882 8.8398 L 30.4491 45.9414 C 30.3789 46.0820 30.3085 46.1289 30.2382 46.1055 C 30.1913 46.1055 30.1210 46.0117 30.1210 45.9180 L 30.2382 27.4023 C 30.2382 26.8164 30.0273 26.4414 29.7929 26.2070 C 29.5585 25.9726 29.1835 25.7617 28.5976 25.7617 L 10.0820 25.8789 C 9.9882 25.8789 9.8945 25.8086 9.8945 25.7617 Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="flx column gap-16">
                    <div v-if="event.itinerary" class="list-row-shading">
                        <div class="gray">Itinerary</div>
                        <div>{{ event.itinerary }}</div>
                    </div>
                    <div v-if="computedClimberGears.length" class="list-row-shading">
                        <div class="gray">Gear required for trip</div>
                        <div>
                            <li class="list-style" v-for="(gear, index) in computedClimberGears" :key="index">{{ gear }}</li>
                        </div>
                    </div>
                    <div v-if="computedGuideGears.length" class="list-row-shading">
                        <div class="gray">Gear provided by Guide</div>
                        <div>
                            <li class="list-style" v-for="(gear, index) in computedGuideGears" :key="index">{{ gear }}</li>
                        </div>
                    </div>
                    <div v-if="computedExperienceLevel.length" class="list-row-shading">
                        <div class="gray">Experience level required</div>
                        <div>
                            <li class="list-style" v-for="(experience, index) in computedExperienceLevel" :key="index">{{ experience }}</li>
                        </div>
                    </div>
                    <div v-if="computedFaqs.length" class="list-row-shading">
                        <div class="gray">FAQ's</div>
                        <div class="flx gap-16 column">
                            <li v-for="faq in computedFaqs" :key="faq.id">
                                <div>
                                    <i class="gray">Question: </i>
                                    <span>{{ faq.question }}</span>
                                </div>
                                <div>
                                    <i class="gray">Answer: </i>
                                    <span>{{ faq.answer }}</span>
                                </div>
                            </li>
                        </div>
                    </div>
                </div>
                <div class="flx column gap-8 mb-16">
                    <div class="gray">Gallery</div>
                    <div class="grid gap-16 gallery" v-if="event.gallery && event.gallery.length">
                        <img @click="$store.commit('showGallery', {event: event, index: index})" v-for="(image, index) in JSON.parse(event.gallery)" :key="index" :src="image ? s3bucket+'/'+ image: ''" :alt="'Gallary image '+index" class="br-16" />
                    </div>
                </div>
                <div v-if="guide && !is_guide" class="flx column gap-8 guide">
                    <div class="gray">Guide for event</div>
                    <div class="flx ai-c gap-16 collapsible">
                        <div class="flx">
                            <user-list :user="guide" :climber="true" :redirect="false" />
                        </div>
                        <div v-if="event.guides_link">
                            <guides-link-button :link="event.guides_link" />
                        </div>
                    </div>

                    <!-- <div class="flx ai-c gap-16 collapsible">
                        <div class="flx">
                            <user-list :user="guide" :climber="true" :redirect="false" />
                        </div>
                        <div v-if="event.guides_link">
                            <guides-link-button :link="event.guides_link" />
                        </div>
                    </div> -->
                </div>
                <div v-if="is_climber" class="sticky booking-trigger-wrapper flx jc-fe">
                    <booking-trigger-button :eventStatus="event" :resultType="'event'" @booking-trigger="bookingTrigger" />
                </div>
            </div>
            <div class="w-50" v-if="is_guide">
                <div class="mb-24">
                    <strong>Registered climbers</strong>
                </div>
                <div v-if="computedBookings.length" class="flx column gap-4">
                   <event-atendees-list v-for="booking in computedBookings" :key="booking.id" :booking="booking" />
                </div>
                <div v-else>
                    <div>No climber is registered for this event yet</div>
                    <span class="gray">Registered climber will appear here</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatDateTime from '@/mixins/formatDateTime'
import userRolesMixin from '@/mixins/userRolesMixin'
import amountFormatter from '@/mixins/amountFormatter'
import { mapState, mapGetters } from 'vuex'
import BookingTriggerButton from '@/components/includes/BookingTriggerButton.vue'
import UserList from '@/components/includes/UserList.vue'
import BookingStatus from '@/components/includes/BookingStatus.vue'
import EventAtendeesList from '@/components/includes/EventAtendeesList.vue'
import EventType from '@/components/includes/EventType.vue'
import textLimitMixin from '@/mixins/textLimitMixin'
import GalleryButton from '@/components/includes/GalleryButton.vue'
import GuidesLinkButton from '@/components/includes/GuidesLinkButton.vue'
export default {
    components: { BookingTriggerButton, UserList, BookingStatus, EventAtendeesList, EventType, GalleryButton, GuidesLinkButton },
    name: 'EventDetails',
    props: {
        event: Object
    },
    mixins: [userRolesMixin, formatDateTime, amountFormatter, textLimitMixin],
    computed: {
        ...mapGetters(['getDevice']),
        ...mapState({
            s3bucket: (state) => state.s3bucket,
            hostname: (state) => state.hostname,
            token: (state) => state.token,
            bookings: (state) => state.bookings,
        }),
        computedPriceRange() {
            if(this.event && this.event.price && JSON.parse(this.event.price).length) {
                const priceArray = JSON.parse(this.event.price)
                return priceArray[priceArray.length - 1].price
            }
            else
            return null
        },
        computedAddressLink(){
            const lat = this.event.latitude
            const lng = this.event.longitude
            return `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${lat},${lng}&travelmode=driving`
        },
        computedOtherLocations() {
            if (this.event.other_location && Array.isArray(JSON.parse(this.event.other_location))) {
                return JSON.parse(this.event.other_location).map(location => {
                    return {
                        ...location,
                        link: `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${location.lat},${location.lng}&travelmode=driving`
                    }
                })
            }
            else
                return []
        },
        computedPriceRangePublic() {

            const parsedPrice = this.event.price ? JSON.parse(this.event.price) : [];
            if (Array.isArray(parsedPrice) && parsedPrice.length) {
                
                const priceArray = Array.from(
                    { length: +this.event.attendance_limit - this.event.attendance_min + 1 },
                    (_, i) => ({
                        id: this.event.attendance_min + i,
                        label: this.event.attendance_min + i === 1 ? '1 person' : `${this.event.attendance_min + i} persons`,
                        price: JSON.parse(this.event.price)[i]?.price ?? null,
                        priceh: JSON.parse(this.event.price)[i]?.priceh ?? null

                    })
                )
                // console.log(priceArray)
                // const priceArray = JSON.parse(this.event.price)
                return priceArray
                
            }else{
                return Number(this.event.price)
            }
        
        },
        isPriceMultiple() {
            if(JSON.parse(this.event.price).length){
                return true
                
            }else{
                return false
            }
        },
        multiDuration() {
            var durations = this.event.event_duration
            if(/^\[.*\]$/.test(durations)){
                durations = durations.replace('[', '').replace(']', '').replace(/"/g, '').split(',')
                // durationarray = [this.event.event_duration.replace('[', '').replace(']', '').replace('"', '').replace(/"/g, '')]
            }else{
                durations = [durations]
            }
            return durations;
        },
        computedFaqs() {
            if(this.event.faqs) {
                return JSON.parse(this.event.faqs).filter(data => data.question || data.answer)
            }
            else
            return []
        },
        computedClimberGears() {
            if(this.event.climber_gears && Array.isArray(JSON.parse(this.event.climber_gears))) {
                return JSON.parse(this.event.climber_gears)
            }
            else
            return []
        },
        computedGuideGears() {
            if(this.event.guide_gears && Array.isArray(JSON.parse(this.event.guide_gears))) {
                return JSON.parse(this.event.guide_gears)
            }
            else
            return []
        },
        computedExperienceLevel() {
            if(this.event.experience_required && Array.isArray(JSON.parse(this.event.experience_required))) {
                return JSON.parse(this.event.experience_required)
            }
            else
            return []
        },
        computedBookings() {
            return this.bookings.length ? this.bookings.filter(booking => booking.event_id === this.event.id ) : []
        },
        computedGuideId() {
            return this.is_super ? this.event.user_id : this.event.guide_id
        }
    },
    data() {
        return {
            guide: {},
            event_durations: []
        }
    },
    watch: {
        event(newEvent) {
            this.getThisGuide(this.is_super ? newEvent.user_id : newEvent.guide_id)
        }
    },
    methods: {
        bookingTrigger() {
            this.$store.commit('triggerBooking', this.event)
        },
        getThisGuide(guide) {
            if(guide) {
                this.$store.dispatch('getThisGuide', guide)
                .then((res) => {
                    this.guide = res.data
                })
            }
            
        }
    },
    mounted() {
        this.getDevice == 'mobile' ? document.body.classList.add('fixed-body') : ''
        this.event.user_id ? this.getThisGuide(this.computedGuideId) : ''

      
        

        // console.log(this.event_durations)
        // this.event.duration
    },
    unmounted() {
        document.body.classList.remove('fixed-body')
    }
}
</script>

<style lang="scss" scoped>
.gallery img {
    max-height: 155px;
}
.grid {
    grid-template-columns: 1fr 1fr 1fr;
}
.bg-img {
    width: 180px;
    height: 210px;
}
.guide img {
    height: 150px;
    width: 150px;
}
.sticky {
    bottom: 0px;
}
.dynamic-padding {
    padding: 32px calc(8vw - 29px);
}
.profile-img {
    height: 300px;
}
@media screen and (max-width: 599px){
    .collapsible{
        align-items: baseline;
    }
}


.pricelist_cover{
    background-color: var(--list-hover);
    padding: 6px 10px;
    width: max-content;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin:10px 0;
    font-size: 0.9rem;
}
.pricelist_amount{
    background-color: white;
    padding: 4px 9px;
    width: max-content;
    border-radius: 20px;
    font-weight: 700;
}
.pricelist_text{
    padding: 4px 9px;
    
}
</style>