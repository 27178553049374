<template>
    <section class="centered">
        <div class="text-center centered gap-16">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" height="60" viewBox="0 0 55.586 63.522">
                    <path d="M-3658.031-261.978a5.975,5.975,0,0,1-5.969-5.968v-27.778a5.976,5.976,0,0,1,5.969-5.968h5.936v-7.92a15.806,15.806,0,0,1,1.25-6.186,15.823,15.823,0,0,1,3.4-5.049,15.829,15.829,0,0,1,5.05-3.405,15.8,15.8,0,0,1,6.185-1.249,15.8,15.8,0,0,1,6.185,1.249,15.83,15.83,0,0,1,5.05,3.405,15.826,15.826,0,0,1,3.4,5.049,15.789,15.789,0,0,1,1.25,6.186v7.92h5.937a5.975,5.975,0,0,1,5.968,5.968v27.778a5.975,5.975,0,0,1-5.968,5.968ZM-3660-295.723v27.778a1.97,1.97,0,0,0,1.969,1.968h43.65a1.97,1.97,0,0,0,1.968-1.968v-27.778a1.97,1.97,0,0,0-1.968-1.968h-43.65A1.97,1.97,0,0,0-3660-295.723Zm35.682-5.968v-7.92a11.812,11.812,0,0,0-3.482-8.407,11.813,11.813,0,0,0-8.406-3.481,11.815,11.815,0,0,0-8.406,3.481,11.813,11.813,0,0,0-3.482,8.407v7.92ZM-3644-281.978a8.009,8.009,0,0,1,8-8,8.009,8.009,0,0,1,8,8,8.009,8.009,0,0,1-8,8A8.009,8.009,0,0,1-3644-281.978Zm4,0a4,4,0,0,0,4,4,4,4,0,0,0,4-4,4,4,0,0,0-4-4A4.005,4.005,0,0,0-3640-281.978Z" transform="translate(3664 325.5)" fill="#f23838"/>
                </svg>
                <h2 class="danger">Access denied!</h2>
                <div>It looks like you cannot access this page</div>
                <div>Contact your administrator.</div>
            </div>
            <a class="ai-c a-button button-primary btn-sm" href="#" @click.prevent="$router.go(-1)">
                Go back
            </a>
        </div>
    </section>
</template>

<script>
export default {
    name: 'AccessDenied'
}
</script>

<style lang="scss" scoped>
section {
    height: calc(100dvh - 116px);
}
</style>