<template>
    <div class="logon-column-wrapper flx column ai-c br-16 bg-white">
        <div class="flx column column-inner ai-c gap-24 h-100">
            <div class="mb-32">
                <logo />
            </div>
            <div class="flx ai-c flx-1 pb-20">
                <div class="flx-1 flx column gap-24 ai-c">
                    <ul class="flx gap-8 logon-stepper mb-24">
                        <li :class="[{ 'active' : $route.path === '/company-info'}, { 'completed' : newGUide.name }]">
                            <svg class="completed-check"  xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 14.347 11.549">
                                <path d="M5.872,10.427l6.936-6.936-.932-.916-6.02,6L3.091,5.8l-.932.932Zm0,1.849L.326,6.73,3.091,3.949,5.872,6.73l5.987-6,2.814,2.748Z" transform="translate(-0.326 -0.727)" fill="#7afc96"/>
                            </svg>
                            <a href="#" @click.prevent class="centered">
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 17.417 19">
                                    <path d="M-3508.083-442.334c0-3.259,1.537-3.958,8.708-3.958s8.708.7,8.708,3.958-1.537,3.959-8.708,3.959S-3508.083-439.074-3508.083-442.334Zm3.958-10.292a4.749,4.749,0,0,1,4.75-4.749,4.749,4.749,0,0,1,4.749,4.749,4.749,4.749,0,0,1-4.749,4.75A4.75,4.75,0,0,1-3504.125-452.625Z" transform="translate(3508.083 457.375)"/>
                                </svg>
                            </a>
                            <span>Company Information</span>
                        </li>
                        <li :class="[{ 'active' : $route.path === '/guide-documents'},{ 'completed' : newGUide && ((newGUide.guide_awards && newGUide.guide_awards != '') || (newGUide.guide_insurance && newGUide.guide_insurance.length) || (newGUide.guide_certificate && newGUide.guide_certificate.length) || (newGUide.guide_license && newGUide.guide_license.length)) } ]">
                            <svg class="completed-check" xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 14.347 11.549">
                                <path d="M5.872,10.427l6.936-6.936-.932-.916-6.02,6L3.091,5.8l-.932.932Zm0,1.849L.326,6.73,3.091,3.949,5.872,6.73l5.987-6,2.814,2.748Z" transform="translate(-0.326 -0.727)" fill="#7afc96"/>
                            </svg>
                            <a href="#" @click.prevent class="centered">
                                <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 16 16">
                                    <path d="M-1982,16a21.453,21.453,0,0,1-4.488-.35,4.2,4.2,0,0,1-2.145-1.017,4.207,4.207,0,0,1-1.017-2.145A21.449,21.449,0,0,1-1990,8a21.449,21.449,0,0,1,.35-4.488,4.2,4.2,0,0,1,1.017-2.145A4.2,4.2,0,0,1-1986.488.35,21.453,21.453,0,0,1-1982,0a21.453,21.453,0,0,1,4.488.35,4.206,4.206,0,0,1,2.145,1.017,4.2,4.2,0,0,1,1.017,2.145A21.449,21.449,0,0,1-1974,8c0,.93-.024,1.728-.075,2.439-.123,0-.247-.008-.392-.015l-.085,0-.284-.011c-.184-.007-.34-.01-.49-.01-.246,0-.469.008-.681.026a3.88,3.88,0,0,0-2.543,1.048,3.779,3.779,0,0,0-1.029,2.507,9.87,9.87,0,0,0-.006,1.157c0,.068.006.133.009.195v.03l0,.091c.01.183.016.333.019.472C-1980.266,15.976-1981.066,16-1982,16Zm-3.932-8.685a.8.8,0,0,0-.8.8.8.8,0,0,0,.8.8H-1978a.8.8,0,0,0,.8-.8.8.8,0,0,0-.8-.8Zm2.346-4a.8.8,0,0,0-.8.8.8.8,0,0,0,.8.8h3.239a.8.8,0,0,0,.8-.8.8.8,0,0,0-.8-.8Zm5.629,12.424h0c0-.1-.008-.2-.013-.306v-.013l0-.086,0-.062c0-.068-.007-.136-.01-.2a8.644,8.644,0,0,1,0-.971,2.237,2.237,0,0,1,.566-1.495,2.343,2.343,0,0,1,1.542-.582c.169-.014.349-.02.552-.02.13,0,.272,0,.434.009.1,0,.2.007.3.012l.327.014c-.029.168-.058.316-.089.455a4.206,4.206,0,0,1-1.017,2.145,4.206,4.206,0,0,1-2.145,1.017c-.133.029-.278.058-.444.088Z" transform="translate(1990)"/>
                                </svg>
                            </a>
                            <span>Insurance, Certificate & Awards</span>
                        </li>
                        <li :class="[{ 'active' : $route.path === '/guide-reviews'}, { 'completed' : newGUide.customer_reviews}]">
                            <svg class="completed-check" xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 14.347 11.549">
                                <path d="M5.872,10.427l6.936-6.936-.932-.916-6.02,6L3.091,5.8l-.932.932Zm0,1.849L.326,6.73,3.091,3.949,5.872,6.73l5.987-6,2.814,2.748Z" transform="translate(-0.326 -0.727)" fill="#7afc96"/>
                            </svg>
                            <a href="#" @click.prevent class="centered">
                                <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 16 15.998">
                                    <path d="M9.019,0a1.091,1.091,0,0,0-.724.31,2.654,2.654,0,0,0-.411.5A9.256,9.256,0,0,0,7.2,2.117C6.77,3.083,6.384,4.193,6.182,4.8a.024.024,0,0,1-.022.016,27.187,27.187,0,0,0-2.8.226,7.189,7.189,0,0,0-1.395.32,2.019,2.019,0,0,0-.578.314A.992.992,0,0,0,1,6.441a1.358,1.358,0,0,0,.183.638,3.286,3.286,0,0,0,.347.5,9.9,9.9,0,0,0,.973.994c.724.659,1.563,1.309,2.04,1.668a.023.023,0,0,1,.008.025c-.183.6-.5,1.7-.7,2.723a9.126,9.126,0,0,0-.19,1.457,2.817,2.817,0,0,0,.047.645,1.192,1.192,0,0,0,.355.66A1.048,1.048,0,0,0,4.829,16a2.2,2.2,0,0,0,.627-.149,7.428,7.428,0,0,0,1.254-.66A27.049,27.049,0,0,0,8.981,13.54a.026.026,0,0,1,.033,0,26.256,26.256,0,0,0,2.279,1.648,7.361,7.361,0,0,0,1.268.66A2.25,2.25,0,0,0,13.2,16a1.086,1.086,0,0,0,.766-.226,1.182,1.182,0,0,0,.382-.662,2.67,2.67,0,0,0,.054-.655,8.536,8.536,0,0,0-.2-1.462c-.213-1.021-.55-2.122-.745-2.726a.023.023,0,0,1,.008-.027c.479-.362,1.316-1.011,2.038-1.668a9.846,9.846,0,0,0,.969-.992,3.276,3.276,0,0,0,.346-.5A1.357,1.357,0,0,0,17,6.441a.992.992,0,0,0-.385-.765,2.016,2.016,0,0,0-.577-.314,7.175,7.175,0,0,0-1.393-.32,27.148,27.148,0,0,0-2.8-.227.024.024,0,0,1-.022-.016c-.2-.608-.574-1.717-.993-2.681A9.144,9.144,0,0,0,10.151.81a2.623,2.623,0,0,0-.408-.5A1.087,1.087,0,0,0,9.019,0Z" transform="translate(-0.998)" fill-rule="evenodd"/>
                                </svg>
                            </a>
                            <span>Customer Reviews</span>
                        </li>
                        <li :class="[{ 'active' : $route.path === '/guide-experience'}, { 'completed' : newGUide.guide_experience}]">
                            <svg class="completed-check" xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 14.347 11.549">
                                <path d="M5.872,10.427l6.936-6.936-.932-.916-6.02,6L3.091,5.8l-.932.932Zm0,1.849L.326,6.73,3.091,3.949,5.872,6.73l5.987-6,2.814,2.748Z" transform="translate(-0.326 -0.727)" fill="#7afc96"/>
                            </svg>
                            <a href="#" @click.prevent class="centered">
                                <svg xmlns="http://www.w3.org/2000/svg" height="17" viewBox="0 0 19 19">
                                    <path d="M-1980.5,19c-4.222,0-6.376-.509-7.683-1.816S-1990,13.722-1990,9.5s.509-6.376,1.816-7.683S-1984.723,0-1980.5,0s6.377.509,7.684,1.816S-1971,5.277-1971,9.5s-.509,6.377-1.816,7.684S-1976.278,19-1980.5,19Zm-.85-7.147h1.7v1.6a.751.751,0,0,0,.751.75.751.751,0,0,0,.75-.75v-1.6h1.6a.751.751,0,0,0,.75-.751.751.751,0,0,0-.75-.75h-1.6v-1.7h1.6a.751.751,0,0,0,.75-.75.751.751,0,0,0-.75-.75h-1.6V5.548a.751.751,0,0,0-.75-.75.751.751,0,0,0-.751.75V7.153h-1.7V5.548a.751.751,0,0,0-.75-.75.751.751,0,0,0-.75.75V7.153h-1.606a.751.751,0,0,0-.75.75.751.751,0,0,0,.75.75h1.606v1.7h-1.606a.751.751,0,0,0-.75.75.751.751,0,0,0,.75.751h1.606v1.6a.751.751,0,0,0,.75.75.751.751,0,0,0,.75-.75v-1.6Zm1.7-1.5h-1.7v-1.7h1.7v1.7Z" transform="translate(1990)"/>
                                </svg>
                            </a>
                            <span>Experience</span>
                        </li>
                        <li :class="[{ 'active' : $route.path === '/guide-reference'}, {'completed' : (newGUide.referees && newGUide.referees[0].name != '' )}]">
                            <svg class="completed-check" xmlns="http://www.w3.org/2000/svg" height="11" viewBox="0 0 14.347 11.549">
                                <path d="M5.872,10.427l6.936-6.936-.932-.916-6.02,6L3.091,5.8l-.932.932Zm0,1.849L.326,6.73,3.091,3.949,5.872,6.73l5.987-6,2.814,2.748Z" transform="translate(-0.326 -0.727)" fill="#7afc96"/>
                            </svg>
                            <a href="#" @click.prevent class="centered">
                                <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 18 11.25">
                                    <path d="M-3593.289-466.75c0-1.853.794-2.249,4.5-2.249s4.5.4,4.5,2.249-.794,2.25-4.5,2.25S-3593.289-464.9-3593.289-466.75Zm9.9,1.125a.563.563,0,0,1,.563-.563,3.386,3.386,0,0,0,1.549-.227.588.588,0,0,0,.254-.268,1.456,1.456,0,0,0,.11-.63,1.269,1.269,0,0,0-.111-.6.62.62,0,0,0-.318-.269,2.909,2.909,0,0,0-.871-.194,15.016,15.016,0,0,0-1.512-.057.563.563,0,0,1-.562-.563.562.562,0,0,1,.563-.563,16.05,16.05,0,0,1,1.63.063,3.994,3.994,0,0,1,1.217.287,1.739,1.739,0,0,1,.843.757,2.361,2.361,0,0,1,.247,1.142,2.561,2.561,0,0,1-.22,1.119,1.714,1.714,0,0,1-.71.756,4.338,4.338,0,0,1-2.107.376A.563.563,0,0,1-3583.389-465.625Zm-13.47.187a1.718,1.718,0,0,1-.709-.756,2.551,2.551,0,0,1-.221-1.119,2.362,2.362,0,0,1,.248-1.142,1.735,1.735,0,0,1,.843-.757,3.987,3.987,0,0,1,1.217-.287,16.05,16.05,0,0,1,1.63-.063.562.562,0,0,1,.563.563.563.563,0,0,1-.562.563,15.016,15.016,0,0,0-1.512.057,2.924,2.924,0,0,0-.872.194.622.622,0,0,0-.317.269,1.269,1.269,0,0,0-.111.6,1.47,1.47,0,0,0,.109.63.593.593,0,0,0,.255.268,3.381,3.381,0,0,0,1.548.227.562.562,0,0,1,.563.563.562.562,0,0,1-.562.563A4.342,4.342,0,0,1-3596.859-465.439Zm11.446-5.249a.563.563,0,0,1,.563-.563,1.129,1.129,0,0,0,1.125-1.125,1.129,1.129,0,0,0-1.125-1.125.563.563,0,0,1-.562-.563.563.563,0,0,1,.563-.563,2.253,2.253,0,0,1,2.25,2.25,2.254,2.254,0,0,1-2.25,2.25A.563.563,0,0,1-3585.414-470.687Zm-6.188-2.25a2.812,2.812,0,0,1,2.813-2.813,2.812,2.812,0,0,1,2.813,2.813,2.813,2.813,0,0,1-2.813,2.813A2.813,2.813,0,0,1-3591.6-472.937Zm-3.375.563a2.254,2.254,0,0,1,2.25-2.25.563.563,0,0,1,.563.563.563.563,0,0,1-.562.563,1.128,1.128,0,0,0-1.125,1.125,1.128,1.128,0,0,0,1.125,1.125.563.563,0,0,1,.563.563.563.563,0,0,1-.562.563A2.254,2.254,0,0,1-3594.977-472.375Z" transform="translate(3597.789 475.75)"/>
                                </svg>
                            </a>
                            <span>References (include at least one)</span>
                        </li>
                    </ul>
                    <router-view></router-view>
                </div>
            </div>
            <foot-note />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Logo from '@/components/includes/Logo.vue'
import FootNote from '@/components/includes/FootNote.vue';
export default {
    components: { Logo, FootNote },
    name: 'SignUpGuideView',
    computed: {
        ...mapState({
            newGUide: (state) => state.data.newGuide
        })
    },
}
</script>

<style lang="scss" scoped>
.logon-column-wrapper {
    padding-top: 60px;
    padding-bottom: 60px;
}
.active {
    span {
        font-weight: 700;
    }
}
</style>