<template>
    <div class="centered br-50 relative wrapper mb-8">
        <div v-if="status.spin" class="avt-overlay absolute centered">
            <spinner :size="20" :color="'#fff'" />
        </div>
        <div v-if="status.tempImage == null && !auth" @click="$emit('upload-click', 'avatar_img')" class="br-50 centered avatar-upload-trigger">
            <svg xmlns="http://www.w3.org/2000/svg" height="22" viewBox="0 0 15.132 15.132">
                <path d="M-1983.684,13.883V8.816h-5.066a1.25,1.25,0,0,1-1.25-1.25,1.25,1.25,0,0,1,1.25-1.25h5.066V1.25a1.25,1.25,0,0,1,1.25-1.25,1.25,1.25,0,0,1,1.249,1.25V6.316h5.066a1.25,1.25,0,0,1,1.25,1.25,1.25,1.25,0,0,1-1.25,1.25h-5.066v5.066a1.249,1.249,0,0,1-1.249,1.249A1.249,1.249,0,0,1-1983.684,13.883Z" transform="translate(1990)" fill="#8c8c8c"/>
            </svg>
        </div>
        <div v-else-if="status.tempImage == null && auth" class="br-50 centered default-avatar">
            <svg xmlns="http://www.w3.org/2000/svg" width="40.781" height="44.487" viewBox="0 0 40.781 44.487">
                <path d="M-468.609-316.513c-4.472,0-7.736-.119-10.271-.375a20.123,20.123,0,0,1-6.221-1.4,5.889,5.889,0,0,1-3.074-2.83,10.563,10.563,0,0,1-.825-4.666,10.548,10.548,0,0,1,.827-4.669,5.888,5.888,0,0,1,3.073-2.827,20.125,20.125,0,0,1,6.22-1.4c2.535-.256,5.8-.375,10.271-.375s7.734.119,10.268.375a20.14,20.14,0,0,1,6.223,1.4,5.9,5.9,0,0,1,3.075,2.83,10.556,10.556,0,0,1,.823,4.665,10.571,10.571,0,0,1-.824,4.667,5.894,5.894,0,0,1-3.073,2.828,20.135,20.135,0,0,1-6.224,1.4C-460.876-316.632-464.138-316.513-468.609-316.513Zm0-14.829c-4.346,0-7.491.113-9.9.356a17.308,17.308,0,0,0-5.06,1.085,2.25,2.25,0,0,0-1.275,1.079,7.462,7.462,0,0,0-.451,3.042,7.482,7.482,0,0,0,.45,3.04,2.251,2.251,0,0,0,1.275,1.08,17.306,17.306,0,0,0,5.061,1.085c2.408.243,5.553.356,9.9.356s7.488-.113,9.9-.356a17.321,17.321,0,0,0,5.062-1.085,2.254,2.254,0,0,0,1.275-1.079,7.493,7.493,0,0,0,.448-3.042,7.481,7.481,0,0,0-.447-3.038,2.255,2.255,0,0,0-1.277-1.082,17.328,17.328,0,0,0-5.062-1.084C-461.12-331.229-464.265-331.342-468.609-331.342Zm0-7.415a11.135,11.135,0,0,1-11.123-11.122A11.134,11.134,0,0,1-468.609-361a11.134,11.134,0,0,1,11.123,11.12A11.135,11.135,0,0,1-468.609-338.757Zm0-18.535a7.423,7.423,0,0,0-7.415,7.413,7.424,7.424,0,0,0,7.415,7.415,7.424,7.424,0,0,0,7.415-7.415A7.422,7.422,0,0,0-468.609-357.292Z" transform="translate(489 361)" fill="#fff"/>
            </svg>
            <button class="br-50 add absolute shadow-sm" @click="$emit('upload-click', 'avatar_img')">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 12 12">
                    <path d="M-3382.143-863.117c-.44-.227-.66-.34-.708-.641s.18-.528.634-.982l2.478-2.478c.454-.454.68-.68.981-.633s.415.266.642.706a.971.971,0,0,1,.117.452c0,.5-.4.9-1.2,1.7l-.794.795c-.8.8-1.2,1.2-1.7,1.2A.96.96,0,0,1-3382.143-863.117Zm-2.7-1.682-2.272-.959a3.719,3.719,0,0,1-2.166-2.863L-3390-873.6c-.036-.247.254-.331.43-.155l2.432,2.432a1.141,1.141,0,0,1,.184.21c.039.064.134.379.325,1.009a1.209,1.209,0,0,0,.3.508,1.218,1.218,0,0,0,1.722,0,1.219,1.219,0,0,0,0-1.723,1.217,1.217,0,0,0-.507-.3c-.63-.191-.945-.286-1.009-.324a1.185,1.185,0,0,1-.21-.185l-2.432-2.432c-.176-.176-.093-.465.154-.43l4.982.72a3.714,3.714,0,0,1,2.862,2.165l.96,2.273c.151.36.228.54.193.72s-.174.318-.45.595l-3.468,3.467c-.276.277-.415.415-.594.45a.458.458,0,0,1-.089.008A1.852,1.852,0,0,1-3384.839-864.8Z" transform="translate(3390 875)"/>
                </svg>
            </button>
        </div>
        <div v-else class="bg-img br-50" :style="{ backgroundImage: 'url('+s3bucket+'/'+status.tempImage+')'}">
            <button class="br-50 delete absolute" @click="this.$emit('deleteTemp')">
                <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 17.5 21">
                    <path d="M-3568.023-836.575a6.145,6.145,0,0,1-1.2-2.411c-.266-.878-.493-1.9-.78-3.2l-.152-.686c-.69-3.1-.346-5.1,1.082-6.3a6.375,6.375,0,0,1,2.894-1.23,22.93,22.93,0,0,1,4.43-.345,22.933,22.933,0,0,1,4.431.345,6.39,6.39,0,0,1,2.894,1.23c1.427,1.2,1.771,3.2,1.082,6.3l-.151.684c-.287,1.3-.514,2.326-.78,3.2a6.145,6.145,0,0,1-1.2,2.411c-1.031,1.148-2.731,1.575-6.273,1.575S-3566.992-835.427-3568.023-836.575Zm2.189-12.115a4.815,4.815,0,0,0-2.116.857c-1.145.958-.832,3.072-.5,4.578q.08.355.152.686c.279,1.264.5,2.262.745,3.075a4.667,4.667,0,0,0,.829,1.75c.463.517,1.387.995,4.971.995s4.508-.478,4.971-.995a4.672,4.672,0,0,0,.828-1.75c.247-.815.467-1.812.746-3.074q.073-.332.152-.687c.7-3.146.051-4.119-.5-4.578a4.82,4.82,0,0,0-2.116-.857,21.307,21.307,0,0,0-4.085-.31A21.3,21.3,0,0,0-3565.834-848.69Zm6.709,8.44v-5.25a.875.875,0,0,1,.875-.876.875.875,0,0,1,.875.876v5.25a.874.874,0,0,1-.875.875A.874.874,0,0,1-3559.125-840.25Zm-3.5,0v-5.25a.876.876,0,0,1,.875-.876.876.876,0,0,1,.876.876v5.25a.875.875,0,0,1-.876.875A.875.875,0,0,1-3562.625-840.25Zm-3.517,0v-5.25a.875.875,0,0,1,.875-.876.875.875,0,0,1,.875.876v5.25a.874.874,0,0,1-.875.875A.875.875,0,0,1-3566.142-840.25Zm11.816-10.625a14.576,14.576,0,0,0-7.424-1.625c-3.911,0-6.362.943-7.4,1.611a.876.876,0,0,1-1.21-.264.875.875,0,0,1,.264-1.209,14.509,14.509,0,0,1,6.6-1.827v-.937a.875.875,0,0,1,.875-.875h1.751a.875.875,0,0,1,.875.875v.937a14.935,14.935,0,0,1,6.576,1.814.874.874,0,0,1,.3,1.2.875.875,0,0,1-.75.424A.869.869,0,0,1-3554.326-850.875Z" transform="translate(3570.5 856)" fill="#fff"/>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Spinner from './Spinner.vue'
export default {
    name: 'AvatarUploader',
    components: { Spinner },
    props: {
        status: Object,
        hostname: String,
        dimension: Number,
        auth: Boolean,
        color: String
    },
    computed: {
        ...mapState({
            s3bucket: (state) => state.s3bucket
        }),
        computedDimension() {
            return this.dimension + 'px'
        }
    }
}
</script>

<style lang="scss" scoped>
$dimension: v-bind(computedDimension);
.wrapper{
    height: $dimension;
    width: $dimension;
    color: #fff;
}
.default-avatar{
    background-color: v-bind(color);
    height: inherit;
    width: inherit;
    cursor: pointer;
}
.bg-img{
    height: inherit;
    width: inherit;
    border: 1px solid var(--bg-color);
}
button {
    padding: 0;
    height: 40px;
    width: 40px;
    border: 2px var(--bg-white) solid;
    right: -7%;
    bottom: -4%;
}
button.delete {
    background-color: rgba(255, 81, 81, 0.8);
    &:hover {
        background-color: var(--danger);
    }
}
button.add {
    background-color: rgb(255, 255, 255);
    &:hover {
        background-color: #eeeeee;
    }
}
.avatar-upload-trigger {
    height: inherit;
    width: inherit;
    border: 1px solid #D9D9D9;
    cursor: pointer;
    transition: var(--input-btn-transition);
    path {
        transition: var(--input-btn-transition);
    }
    &:hover {
        border-color: #000;
        background-color: #fafafa;
        path {
            fill: #000;
        }
    }
    &:active {
        background-color: unset;
    }
}
</style>