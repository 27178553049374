<template>
  <div class="elementor-element elementor-element-7d7d176 e-flex e-con-boxed e-con e-parent e-lazyloaded" data-id="7d7d176" data-element_type="container" data-settings="{&quot;jet_parallax_layout_list&quot;:[],&quot;background_background&quot;:&quot;classic&quot;}">
    <div class="e-con-inner">
      <div class="elementor-element elementor-element-c31fc32 elementor-widget elementor-widget-heading" data-id="c31fc32" data-element_type="widget" data-widget_type="heading.default">
        <div class="elementor-widget-container">
          <h2 class="elementor-heading-title elementor-size-default">Terms and Conditions</h2>		</div>
      </div>
      <div class="elementor-element elementor-element-c2b602f e-con-full e-flex e-con e-child" data-id="c2b602f" data-element_type="container" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;jet_parallax_layout_list&quot;:[]}">
        <div class="elementor-element elementor-element-7d55aaf elementor-widget elementor-widget-text-editor" data-id="7d55aaf" data-element_type="widget" data-widget_type="text-editor.default">
          <div class="elementor-widget-container">
            <p><!-- wp:heading {"jetDynamicVisibility":{},"jetEngineDynamicData":{}} --></p>
            <p><em>Last Updated: March 6, 2024</em></p>
            <p>These terms of use (these “<strong>Terms</strong>”) apply to both your access to, and your use of, the websites, online services or other electronic locations, and materials, Content or services that we, Climb Onsight, Inc. (“Climb Onsight”), make available from time to time, including www.climbonsight.ca (collectively, as it relates to these Terms, “<strong>This Site</strong>”).&nbsp; This Site allows users (“Customer”) and third-parties who offer guided outdoor trips (“Guide”) to connect, communicate, and book a guided trip (“Guided Trip”). These Terms apply to your use of This Site, whether or not you are a Customer or a Guide. By accessing and using This Site, you accept and agree to be bound by these Terms, and all applicable laws and regulations.</p>
            <p>We have made an effort to ensure that these Terms reflect, in plain language, your obligations, rights and remedies—while this makes these Terms a little bit longer than others might be, it is organized by headers to help you find what you need.&nbsp; <u>PLEASE READ THESE TERMS CAREFULLY. THEY CONTAIN IMPORTANT INFORMATION REGARIDNG YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS, INCLUDING, BUT NOT LIMITED TO, VARIOUS LIMITATIONS, EXCLUSIONS AND INDEMNITIES.</u></p>
            <p>Unless we expressly disclaim any terms in writing, your use of This Site and any Content we provide (we will define that below) will <u>ALWAYS</u> be subject to terms of use, and so your use of This Site is subject to these Terms.&nbsp; If you are not willing to be bound by each and every provision of these Terms, or if anything that we require you to agree to in these Terms is not true, you may not use This Site and must immediately cease any access or use This Site.</p>
            <p>To be clear, in these Terms when we use the phrase “<strong>This Site</strong>”, we include any Content made available through or using This Site, as will be explained further below.</p>
            <p><u>YOU MAY NOT USE THIS SITE (WHICH INCLUDES ANY MATERIALS, CONTENT OR SERVICES MADE AVAILABLE THROUGH IT) WITHOUT AGREEING TO THESE TERMS.</u></p>
            <h3>1. Changes to These Terms</h3>
            <p><strong><em>a. How changes happen</em></strong>—We may change these Terms from time to time without notifying you, but we will use reasonable efforts to publish each amendment before it becomes effective.&nbsp; We will ensure that the latest, fully-amended version of these Terms are published on or linked through This Site.&nbsp; You are responsible for regularly reviewing This Site to obtain timely notice of any terms that apply to you.</p>
            <p><strong><em>b. How to reject changes</em></strong>—If any amendment is unacceptable to you, you may terminate your agreement to these Terms (as amended) by ceasing any use of This Site or any content or materials you obtained through them.&nbsp; If you have an Account with us (see below about Accounts), you must also close that Account.</p>
            <p><strong><em>c. Your use of changed Terms</em></strong>—If you continue to use This Site after the effective date of an amendment, you agree that it is evidence that you accepted the amended version of these Terms, and that you will be deemed to have accepted the amendments.</p>
            <h3>2. Your Use of This Site is Licensed by Us, and is Subject to These Terms</h3>
            <p><strong><em>a. Our license to you</em></strong>—Subject to these Terms, we grant you permission to access This Site through a personal, non-exclusive, non-transferable, limited license for transitory, non-commercial purposes, provided that with respect to any materials obtained from This Site, you may not do <u>any</u> of the following, unless you have our prior, written permission:</p>
            <p style="padding-left: 40px;">(i) modify, copy, reproduce or create derivative works of such materials (except as explicitly provided in connection with those materials through This Site);</p>
            <p style="padding-left: 40px;">(ii) use the materials for any commercial purpose;</p>
            <p style="padding-left: 40px;">(iii) distribute or transmit the materials;</p>
            <p style="padding-left: 40px;">(iv) publicly display, publish or perform the materials (for any purpose, commercial or non-commercial);</p>
            <p style="padding-left: 40px;">(v) create derivative works from, transfer, or sell any materials;</p>
            <p style="padding-left: 40px;">(vi) attempt to decompile or reverse engineer any software or database contained in or access through This Site; or</p>
            <p style="padding-left: 40px;">(vii) remove any copyright or other proprietary notations;</p>
            <p><em><strong>b.</strong> </em><em style="font-weight: bold;">We maintain our rights</em>—To be clear, all rights not expressly granted to you are reserved by us and, if applicable, our licensors.</p>
            <p><em><strong>c.</strong></em> <strong><em>Clarification on your permissions</em></strong>—You are permitted to provide links to This Site through email, social media, or other similar methods, but you must make sure that such methods do not (i) involve framing or embedding of any portion of This Site within another website or service, (ii) imply any affiliation between you and us, or you and This Site unless expressly authorized by us in writing, (iii) portray us or This Site in a false, misleading, derogatory or otherwise defamatory manner, or (iv) use This Site in a commercial context unless expressly authorized by us in writing.</p>
            <h3>3. Our Privacy Policy applies to These Terms</h3>
            <p><strong><em>a. Where you can find Our Privacy Policy</em></strong>—Our privacy policy applies to your use of This Site, and it is available <a href="https://climbonsight.ca/privacy-policy/">here</a>.&nbsp; We may update that policy from time to time.&nbsp; In these Terms, we refer to the privacy policy as it is updated from time to time as our “<strong>Privacy Policy</strong>”.</p>
            <p><strong><em>b. Your implied consent</em></strong>—By using This Site, it is evidence that you consent to the collection, use and disclosure by us and our agents of your personal information for the purposes identified in our Privacy Policy. &nbsp;We will <u>only</u> use your personal information in accordance with our Privacy Policy.</p>
            <p><em><strong>c. </strong></em><strong><em>Exception to implied consent</em></strong>—If you are from a jurisdiction where your implied consent cannot be inferred from your use of This Site, or if we do not otherwise under the laws applicable to you have the ability to use your personal information as set out in the Privacy Policy, please note that we do not give you permission to use This Site without your express consent.&nbsp; You may contact us with any privacy questions as set out in the Privacy Policy.</p>
            <h3>4. Your Eligibility to Use This Site and its content</h3>
            <p><em><strong>a.</strong></em> <strong><em>You must be legally allowed to use This Site</em></strong>—This Site is intended solely for users who are of the legal age of majority and who reside in a jurisdiction where the content of This Site is legally permissible.&nbsp; Any use of This Site by anyone who does not meet those criteria is unauthorized, unlicensed, and in violation of these Terms.</p>
            <p><strong><em>b.</em> <em>What we may do</em></strong>—We may take actions to protect against such violations, including terminating accounts, deleting or blocking content, or otherwise prohibiting you from using This Site.&nbsp; We may take any actions, including those, within our sole discretion and with or without notice.</p>
            <p><strong><em>c.</em> <em>Whether we do something or not, we have other rights</em></strong>—If we take or do not take any action, it does not in any way waive any other legal right or remedy we may have against you.</p>
            <h3>5. Your Account</h3>
            <p><strong><em>a.</em> <em>Overview of Accounts</em></strong>—This Site allows you to access or use a user account (an “<strong>Account</strong>”).&nbsp;</p>
            <p><strong><em>b.</em> <em>Our Privacy Policy applies to your Account’s personal information—</em></strong>When you create an Account, you will be asked to provide certain information.&nbsp; Like all other personal information, personal information associated with your Account will be subject to our Privacy Policy.</p>
            <p><em><strong>c.</strong></em> <strong><em>Your Account must be yours</em></strong>—You must not register or access an Account on behalf of any individual other than yourself, except for legal entities that are not individuals (such as companies, partnerships, or societies), in which case you may not register or access an Account on behalf of that entity unless you are duly authorized to do act for that entity.&nbsp;</p>
            <p><strong><em>d. Your Account information must be accurate</em></strong>—It is important that your Account is accurate.&nbsp; So, we require that you:</p>
            <ul>
              <li>provide true, current, accurate and complete Account information as requested by us or our agents from time to time; and</li>
              <li>promptly notify us (through the Account features of This Site, or by other contact methods if necessary) of any changes to any Account information.</li>
            </ul>
            <p><strong><em>e. You must keep your Account safe</em></strong>—While we will use reasonable efforts to safeguard personal information as set out in our Privacy Policy, it is you (and not us) who is responsible for making sure that the access credentials to your Account remain secure and confidential, and to verify and monitor any Account activity.</p>
            <p><strong><em>f. Anything done through your Account is done by you</em></strong>—We may assign you, or you may create, a password and Account identification to enable you to access and use certain portions of This Site.&nbsp; Because This Site is accessible through the internet, we will assume that any activity taken through your Account or using your Account credentials is authorized and directed by you.&nbsp; You are legally responsible for all activities that occur under your Account, even if the person gained access to your account without your permission.&nbsp; We have no obligation to investigate the authorization or source of any access or use of The Site that purports to be you, such as activity through your Account.</p>
            <p><strong><em>g. Please keep us informed if something goes wrong</em></strong>—You must immediately notify us if:</p>
            <ul>
              <li>any unauthorized use of your Account, any service provided through your Account or any password related to your Account,</li>
              <li>you become aware of any activity you did not approve, or</li>
              <li>there is any other breach of security with respect to your Account or any service provided through it.</li>
            </ul>
            <p>You must also provide us reasonable assistance to us, as requested, to stop or remedy those occurrences.&nbsp; Contact procedures are set out in these Terms, in our Privacy Policy and on This Site.</p>
            <p><strong><em>h. Third party links are your (and those third parties’) responsibility</em></strong>—If your Account is linked to a third party service, like a payment processor, we may update your information with information provided through that third party service.&nbsp; We are not responsible for that third party information, they (or you, through your agreement with them) are responsible for it.</p>
            <h3>6. Who Owns This Site’s Content</h3>
            <p><strong><em>a. What “Content” means</em></strong>—In these Terms, we use the term “<strong>Content</strong>” to mean all materials and content — this includes designs, editorial, text, graphics, audiovisual materials, multimedia elements, photographs, videos, music, sound recordings, reports, documents, software, information, formulae, patterns, data and any other work.&nbsp; So basically, anything available on or through This Site is Content—including, in the case of our Content, source code, processes, designs, technologies, URLs, domain names, trademarks and logos forming any part of This Site.</p>
            <p><strong><em>b. Our Content is ours, not yours</em></strong>—Except where expressly stated otherwise, all right, title and interest in and to This Site and all Content that you did not provide yourself (“<strong>Our Content</strong>”) fully belongs to us or others that we have licensed it from.&nbsp; Our Content is protected by applicable copyright, trade-mark, patent, trade secret or other proprietary rights or intellectual property laws. Our Content is licensed subject fully to these Terms. Except for the limited license we have already described above, Nothing in your use of This Site grants you any right, title or interest in, or any ownership over, Our Content.</p>
            <p><em><strong>c.</strong></em> <strong><em>We do not control third party Content, which is theirs and not yours</em></strong>—This Site may link to or otherwise make available Content from third parties (collectively, “<strong>Third Party Content</strong>”).&nbsp; That Third Party Content is theirs and not yours, and may be governed by terms and conditions applicable to Third Party Content.&nbsp; Nothing in your use of This Site grants you any right, title or interest in, or ownership over, Third Party Content, even if you may have accessed it legitimately through the license we have given you to use This Site.</p>
            <p><em><strong>d.</strong></em> <strong><em>Your Content is yours</em></strong>—If This Site contains interactive features that allow you to provide Content (“<strong>Your Content</strong>”), we do not claim any ownership over it.&nbsp; Your Content includes Content you post, upload, input, provide, submit or otherwise transfer Your Content to us or any third party, using the This Site.&nbsp;</p>
            <p><strong><em>e. We may use Your Content, and Your Content is prohibited unless we are allowed to use it</em></strong>—You are not allowed to provide us with any of Your Content unless we have the right to use it.&nbsp; Because we have no obligation to monitor Your Content, this is true regardless of what Your Content states, or any context or disclaimers you provide in connection with providing Your Content.&nbsp; So, if you make Your Content available in any way through This Site, we deem that to mean that you have:</p>
            <p style="padding-left: 40px;">(i) <strong><em>Your Content is Fully Licensed for This Site</em></strong>—given us the royalty-free and fully paid-up (meaning we have no obligation to pay you), non-exclusive (meaning you may give it to others), irrevocable and perpetual (meaning that you may not terminate it, which we will explain below), license that is without any territorial or geographic limitation (we will refer to all of that as a “<strong>Full License</strong>”) to use copy, distribute, transmit, display, edit, delete, publish and translate (we will refer to that as “<strong>Fully Use</strong>”) Your Content to the extent we reasonably determine it is necessary for us to provide This Site as it may exist in any medium from time to time;</p>
            <p style="padding-left: 40px;">(ii) <strong><em>Your Content is Fully Licensed so that we may administer these Terms</em></strong>—given use a Full License to Fully Use Your Content in order to ensure adherence to, or enforce, these Terms, or to comply with any applicable law;</p>
            <p style="padding-left: 40px;">(iii) <strong><em>We rely on you to not give us anything unless we are Fully Licensed to Fully Use Your Content</em></strong>—confirmed, represented and warranted to us that you have all right, title and interest, as well as the power and authority necessary, to grant a full license for us to fully use your Content to us as set out above; and</p>
            <p style="padding-left: 40px;">(iv)&nbsp;<strong><em>We may hold you legally responsible if someone claims we cannot use Your Content—</em></strong>agreed to indemnify us from (and disclaim our responsibility for) any actions, proceedings, claims, liabilities, damages and reasonable expenses (including legal expenses) relating to or arising from Your Content, including instances where the Your Content (A) infringes any intellectual property rights, or (B) is inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful.</p>
            <p><em><strong>f.</strong></em> <strong><em>Our obligation to protect Your Content is limited</em></strong>—We will and have established commercially reasonable security measures to ensure that our personnel only use Your Content in accordance with the Full Licenses granted under these Terms.</p>
            <p><strong><em>g. If you do not agree to how These Terms deal with Your Content, do not provide it</em></strong>—If you cannot provide Your Content in accordance with these Terms, we prohibit you from using it in connection with Your Site.</p>
            <p><strong><em>h. Deleting Your Content</em></strong>—If permitted by the functionality of This Site, You may delete Your Content through the Site (or by deleting Your Account), but you acknowledge and agree that, because your Full License is irrevocable and perpetual, we may retain copies of Your Content for archival or compliance purposes or to otherwise provide The Site to you or others, subject always to your Full License to us set out above, even if you delete any Account to which Your Content is connected.&nbsp; You may always contact us regarding Your Content as set out in these Terms, This Site or our Privacy Policy.</p>
            <p><strong><em>i. </em><em>If you give us feedback, you also give us the right to use it</em></strong><em>—</em>If you provide us any comments, ideas, suggestions and impressions of This Site or our products and services (collectively, the “<strong>Feedback</strong>”), you give us a Full License to use it for any purposes, including commercial exploitation in perpetuity, regardless of whether we actively solicited that Feedback or not.</p>
            <p><strong><em>j. We are only responsible for Our Content, not Your Content or Third Party Content</em></strong>—You are exclusively responsible for determining the accuracy, suitability, harmfulness or legality of any of Your Content, even if you did not create it.&nbsp; We do not have any obligation to monitor, censor or review any of Your Content or any Third-Party Content, or to monitor use of This Site. If we do decide, in our sole discretion, to review or take any action with Content other than Our Content, it creates no obligation for us to do that in the future.&nbsp;</p>
            <p><strong><em>k. We may take action if we receive complaints</em></strong>—If we receive a complaint relating to use of the This Site by you, including as it relates to Your Content or your use of Third Party Content, you acknowledge and agree that we may, in our sole and absolute discretion and without notice or liability, investigate the complaint, and restrict, suspend or terminate your access to the This Site, or and censor, alter or remove Your Content, or take any other action that is permitted by applicable law.&nbsp; Nothing we do (or do not do) waives or limits our right to any rights or remedies that we have.</p>
            <h3>7. Intellectual Property Notices and Complaints</h3>
            <p><em><strong>a.</strong></em> <strong><em>Copyright Notice</em></strong>—This Site and all Content is owned and copyrighted by us and our licensors, and is licensed to you in accordance with these Terms only.&nbsp; All rights are reserved.</p>
            <p><strong><em>b. Trademark Notice</em></strong>–The trademarks, logos, and service marks displayed on or through This Site are the property (whether registered or unregistered) of us, our licensors or other third parties. You are not permitted to use trademarks, logos and service marks for any purpose without our prior, written consent.</p>
            <p><em><strong>c.</strong></em> <strong><em>No use of trademarks implies any endorsement, sponsorship or affiliation</em></strong>—Except as expressly indicated on This Site, no endorsement, sponsorship, affiliation or other authorization is implied by any use of third party trademarks.</p>
            <p><strong><em>d. Complaint Requirements</em></strong>—We may avail ourselves of any protections available under applicable laws, such as by registering a designated agent under the <em>Digital Millennium Copyright Act</em> (United States) and complying with those provisions.&nbsp; Please check your local laws to see what rights you may have to make complaints against us.&nbsp; For Canadian copyright purposes, If you believe that any Content on This Site infringes upon any copyright or other intellectual property right that you own or control, you must send a written notification of such infringement to us as follows: Shaun Rosenthal at support@climbonsight.ca, in such form as is required under the <em>Copyright Act </em>(Canada) from time to time, and in that notice you must:</p>
            <p style="padding-left: 40px;">(i) state your name and address and any other particulars prescribed by regulation that enable us and our users to communicate with you;</p>
            <p style="padding-left: 40px;">(ii) identify the work or other subject-matter to which the claimed infringement relates;</p>
            <p style="padding-left: 40px;">(iii) state your interest or right with respect to the copyright in the work or other subject-matter;</p>
            <p style="padding-left: 40px;">(iv) specify the location data (such as a URL or locator) for the electronic location to which the claimed infringement relates;</p>
            <p style="padding-left: 40px;">(v) specify the infringement that is claimed;</p>
            <p style="padding-left: 40px;">(vi) specify the date and time of the commission of the claimed infringement; and</p>
            <p style="padding-left: 40px;">(vii) include any other information that may be prescribed by regulation.</p>
            <p>Upon receipt of that notice, we will comply with our obligations under the Copyright Act (Canada), without limiting our right to provide such other notices to the infringing user.</p>
            <p><strong><em>e. Our infringer and repeat infringer policy</em></strong>—We may terminate, at our sole discretion, users who are deemed to be repeat infringers of intellectual property. We may also, at our sole discretion, limit access to This Site, or terminate any Account, of any user who infringes any intellectual property rights of us or others, whether or not there is any repeat infringement.</p>
            <h3>8. Acceptable Use of This Site, and Prohibitions</h3>
            <p><em><strong>a.</strong> </em>In addition to any other terms or conditions regarding your use of This Site in these Terms, we may require you to agree to specific terms for particular services, products or areas of This Site from time to time by confirming your agreement.&nbsp; Any such additional terms which you agree to will be deemed incorporated into these Terms.</p>
            <p><em><strong>b.</strong></em> <strong><em>Proper conduct (things you must do)</em></strong>—Without limiting anything else contained in these Terms, you must ensure that:</p>
            <p style="padding-left: 40px;">(i) you only use This Site for lawful purposes; and</p>
            <p style="padding-left: 40px;">(ii) if at any time you become aware of any violation, by any person or entity under your control, of any part of these Terms, you will immediately notify us and provide us with assistance, as requested, to stop or remedy such violation.</p>
            <p><em><b>c. Prohibited conduct (things you must not do)</b></em>—Without limiting anything else contained in these Terms, you must not, in connection with This Site, directly or indirectly do or permit any of the following:</p>
            <p style="padding-left: 40px;">(i) post, upload, reproduce, distribute or otherwise transmit any Content that</p>
            <p style="padding-left: 80px;">(1) is unauthorized or unsolicited commercial communications, junk or bulk communications or other “spam” (whether or not using e-mail services, including instant messaging, blog or comment spam) or is otherwise duplicative or unsolicited,</p>
            <p style="padding-left: 80px;">(2) contains any malware, payload, virus, cancelbot, Trojan horse, worm or other harmful, disruptive or surreptitious component, regardless of intent,</p>
            <p style="padding-left: 80px;">(3) is defamatory, infringing, or unlawful,</p>
            <p style="padding-left: 80px;">(4) is inappropriate, profane, obscene, indecent materials or information without suitable or lawfully-required access controls (which controls shall in no event be our responsibility),</p>
            <p style="padding-left: 80px;">(5) gives rise to civil liability, or otherwise violates the rights or assists others to violate the rights of us or any third party, such violations including engaging in copyright infringement, invasion of privacy, trademark infringement or defamation,</p>
            <p style="padding-left: 80px;">(6) constitutes a criminal offence, or otherwise engages in or assists others to engage in any criminal offence, including pyramid selling, unauthorized use of a computer, mischief in relation to data, fraud, obscenity and child pornography; or</p>
            <p style="padding-left: 80px;">(7) incites discrimination, hate or violence towards one person or a group because of their belonging to a race, religion, nationality, sexuality, gender or other human rights-protected group of persons;</p>
            <p style="padding-left: 40px;">(ii) engage in threats, harassment, intimidation, stalking or abuse or any conduct that violates the legal rights of others, including the rights of minors and rights relating to privacy and publicity;</p>
            <p style="padding-left: 40px;">(iii) scan or probe another computer system, obstruct or bypass computer identification procedures or engage in unauthorized computer or network trespass without the express permission of the owners of such computer systems;</p>
            <p style="padding-left: 40px;">(iv) forge headers or otherwise manipulate any protocols or identifiers used in any system or protocol in such a manner to disguise the origin of any data transmitted using This Site;</p>
            <p style="padding-left: 40px;">(v) impersonate or falsely represent your association with any person, including a representative of us;</p>
            <p style="padding-left: 40px;">(vi) disrupt or threaten the integrity, operation or security of any service, computer or any internet system;</p>
            <p style="padding-left: 40px;">(vii) disable or circumvent any access control or related process or procedure established with respect to This Site;</p>
            <p style="padding-left: 40px;">(viii) sublicense, share, resell, reproduce, copy, distribute, redistribute, or exploit for any commercial purposes (except for your internal, personal, non-commercial purposes), any portion of, use of or access to, This Site, except where expressly authorized by us;</p>
            <p style="padding-left: 40px;">(ix) harvest, scrape, extract, gather, collect, or store personal information about others without their express consent;</p>
            <p style="padding-left: 40px;">(x) harvest, scrape, or use any robot, spider, crawler, script or other automated means or interface not provided by us to access This Site or to extract data, collect information or otherwise interact with This Site; or</p>
            <p style="padding-left: 40px;">(xi) access or use This Site in order to copy any Content, or features or functionality thereof, for any commercial purpose.</p>
            <h3>9. Booking a Guided Trip</h3>
            <p><em><strong>a. </strong></em><strong><em>Booking a Guided Trip</em></strong>—Guides will list Guided Trips on This Site. The Customer can request to book a Guided Trip and the Guide will confirm the booking. When the Customer requests to book a Guided Trip, the price of the Guided Trip (“Guide Price”) and applicable taxes, service fees, and processor fees (collectively, “Total Fees”) (collectively, the Guide Price and the Total Fees are referred to as “Trip Cost”), if any, will be presented to the Customer prior to the booking. If a request to book a Guided Trip is made in connection with the Customer’s account, the Customer agrees that they will pay the Trip Cost. To complete the request, the Customer must enter their payment information via the payment processor on This Site. The Customer will only be charged the Trip Cost once the Guide confirms the booking.</p>
            <p><strong><em>b. Reviewing the Booking Prior to Requesting the Booking</em></strong><em>—</em> Prior to requesting to book any trip, the Customer should review any Guide Trip description, so the Customer, and any individual they book on behalf of, meets any requirement set by the Guide, including, but not limited to, any age, proficiency, or fitness requirements. This Site provides contact information for each Guide and the Customer may communicate with the Guide via the chat function on This Site to ask any questions about any of the requirements. It is also the Customer’s responsibility to inform them of any medical or physical conditions that may impact their ability to safely participate in the Guide Trip.</p>
            <p><em><strong>c. </strong></em><strong><em>Agreement Between Customer and the Guide</em></strong>—After the Guide confirms the booking, a legally binding agreement is formed between the Customer and the Guide, subject to any additional agreements or terms that the Customer and the Guide agree to, including any waivers</p>
            <p style="padding-left: 40px;">(i) <strong><em>Customer Agrees to Pay the Trip Cost</em>:</strong> Once the booking is confirmed, the Customer will be charged the Trip Cost pursuant to the payment information the Customer entered when they requested the booking.</p>
            <p style="padding-left: 40px;">(ii) <strong><em>Customer Agrees to Abide by the Guides’ Requirements and Terms</em>:</strong> On the Guide Trip listing on This Site, the Guide may include requirements for the Guide Trip and may impose additional terms and conditions. The Customer represents that they meet the requirements set by the Guide, if any. The Customer also agrees to abide by any listed terms and conditions created by the Guide on the listing, if any. The Guide may in their sole discretion require the Customer and any individual participating in the Guide Trip to sign a liability waiver or an assumption of risk form.</p>
            <p style="padding-left: 40px;">(iii) <strong><em>Customer Agrees that Additional Customers</em> <em>Must Abide By the Terms and Conditions</em>:</strong> Customer agrees that if the Customer books a Guide on behalf of additional individuals (“Additional Customers”) (collectively, the Customer and Additional Customers are referred to as “Trip Customers”), Customer agrees that each Additional Customer meets the requirements set by the Guide, is aware of and agrees to these Terms and Conditions (including any cancellation policy instituted by the Guide), and is aware of and agrees to any terms set by the Guide. If the Additional Customer is a minor, the Customer represents and warrants that they are the parent or legal guardian of the minor and that they consent to the collection, use and disclosure of personal information of the minor in accordance with the Privacy Policy.</p>
            <p style="padding-left: 40px;">(iv) <strong><em>Cancellations</em>:</strong> The Guide may include in the listing description a cancellation policy. If the listing does not include a cancellation policy prior to the time the Customer requested to book the Guide Trip, the following cancellation policy applies:</p>
            <p style="padding-left: 80px;">(1) <em>Weather Related Cancellations</em>: Guides have the sole discretion to cancel the booking if weather or other environmental conditions make it unsafe or inappropriate to complete the Guide Trip. <u>Prior to the Guide Trip</u>, if there is a substantial change in the Guide Trip or the Guide Trip needs to be cancelled, the Guide and the Trip Customer will work together to arrange an alternate date for the guided trip, a credit for the Guide’s services, or an appropriate refund. The Guide and Trip Customer will inform Climb Onsight of the agreed upon resolution. If the Guide and Trip Customer cannot reach a resolution, they will inform Climb Onsight and we will provide a refund to the Trip Customer.</p>
            <p style="padding-left: 80px;"><u>During the Guide Trip</u>, if the Guide determine weather, environmental, or personal circumstances make continuing the Guide Trip unsafe or inappropriate, the Guide may, in their sole discretion, end the Guide Trip prematurely. Climbing and mountaineering can be dangerous activities and many factors affect whether it is safe to continue with the activity. The Trip Customers agree that they accept the risk that the Guide may need to end the trip prematurely because of factors outlined above. Accordingly, once a Guide Trip begins, we will not offer refunds for classes that end prematurely. Nonetheless, the Guide and the Trip Customer may agree to refund, in whole or in part, the Trip Cost or provide a full or partial credit of the Trip Cost.</p>
            <p style="padding-left: 80px;">(2) <em>Guide Cancellations</em>: if, for any reason other than described above, the Guide needs to cancel a Guide Trip, the Guide and the Trip Customer will work together to arrange an alternate date for the guided trip, a credit for the Guide’s services, or an appropriate refund. The Guide and Trip Customer will inform Climb Onsight of the agreed upon resolution. If the Guide and Trip Customer cannot reach a resolution, they will inform Climb Onsight and we will provide a refund to the Trip Customer.</p>
            <p style="padding-left: 80px;">(3) <em>Customer Cancellations: </em></p>
            <p style="padding-left: 120px;">(a) <em>Fourteen (14) calendar days prior to the Guide Trip</em>: Customer is entitled to a full refund of the Trip Cost.</p>
            <p style="padding-left: 120px;">(b) <em>Seven (7) to thirteen (13) calendar days prior to the Guide Trip</em>: Customer is entitled to a credit equal to the Trip Cost with that Guide or a refund of fifty percent (50%) of the Trip Cost.</p>
            <p style="padding-left: 120px;">(c) <em>Six (6) calendar days prior to the Guide Trip</em>: The Customer is not entitled to a refund.</p>
            <p style="padding-left: 120px;">(d) <em>After the Guide Trip Begins</em>: After the Guide Trip is scheduled to begin, if any Trip Customer decides to not continue with their climb, no credits or refunds will be provided.</p>
            <p style="padding-left: 80px;">(4) <em>Subsequent Agreement Between the Customer and the Guide</em>: After the Customer cancels the Guide Trip, the Customer and the Guide may decide on an alternative refund for the Trip Cost. The Customer and the Guide must inform Climb Onsight of this subsequent agreement so the appropriate refund can be processed.</p>
            <h3>10. Climb Onsight’s E-Commerce Store</h3>
            <p><strong><em>a. Climb Onsight offers products from various vendors—</em></strong>On our e-commerce store, available at www.climbonsight.ca/marketplace (“Marketplace”), we may offer products sold by a variety of third-party vendors (“Vendors”). The Vendors are provided an opportunity to draft and publish a refund and return policy applicable to the goods and services they offer within the Marketplace, which, if drafted, applies to all goods and services sold by the Vendor.</p>
            <p><strong><em>b. Climb Onsight’s Return and Refund Policy</em></strong><em>—</em>We are a small company, and we outsource our inventory, manufacturing, and shipping. We may not be able to accept any return or refund requests. But, if you purchase a product sold by us (i.e., by Climb Onsight and not a Vendor), and within 30 days, you wish to have it refunded or exchanged, please reach out to support@climbonsight.ca and provide the following information:</p>
            <p style="padding-left: 40px;">(i) A copy of your order receipt;</p>
            <p style="padding-left: 40px;">(ii) The reason for your return; and</p>
            <p style="padding-left: 40px;">(iii) Whether you want a refund or exchange.</p>
            <p>Upon receiving this information, we will do our best to assist you.</p>
            <h3>11. Our Termination or Curtailment of Your Access to <em>This</em> Site</h3>
            <p><strong><em>a. We have full discretion to terminate at any time</em></strong>—We may, in our sole discretion, suspend, restrict or terminate your use of This Site, including your Account and Your Content, effective at any time, without notice to you, for any reason.&nbsp; It is impossible for us to list all of the reasons we may decide to do so, but, without limiting the generality of the foregoing, we may do so if (i) the operation or efficiency of This Site or any third party’s equipment or network is impaired by your use of This Site, (ii) we have received a third party complaint which relates to your use or misuse of This Site, or (iii) you have been or are in breach of any term or condition of these Terms.</p>
            <p><strong><em>b. If we terminate, it affects Your Content and use of This Site</em></strong>—You acknowledge and agree that our termination, curtailment, or suspension of This Site for any reason may result in restrictions of, disruptions to or cessation of your or third party access to your Account and Your Content.&nbsp; We claim no responsibility for any liability or claims of loss resulting from restrictions, disruptions or cessations, and by using This Site we will deem you to have agreed to release us from those claims. If your use of This Site is suspended or restricted, we will have no obligation to forward any of Your Content to you or any third party unless required by applicable law.</p>
            <h3>12. Disclaimers, Limits of Liability, Release of Liability, and Indemnities</h3>
            <p><strong><em>a. </em></strong><strong><em>Scope of this Section</em></strong>—This “Disclaimers, Limits of Liability, Release of Liability, and Indemnities” section covers the disclaimers, limits of liability, release of liability, and indemnities between you and Climb Onsight, our employees, directors, shareholders, officers, affiliates or controlling parties, agents, software suppliers, insurance carriers (when we refer to “<strong>we</strong>” or “<strong>us</strong>” in this “Disclaimers, Limits of Liability, Release of Liability, and Indemnities” section, we mean us and all of these people). This section also covers the disclaimers, limits of liability, release of liability, and indemnity between any Additional Customer and us. This section does not cover any disclaimers, limits of liability, release of liability, and indemnities between a subscriber and a Customer and a Guide.</p>
            <p><strong><em>b. Disclaimer about internet-based limitations</em></strong>—Your use of This Site depends on the public internet, including networks, cabling, facilities and equipment that is not in our control; accordingly (i) we cannot guarantee any minimum level regarding such performance, speed, reliability, availability, use or consistency, and (ii) data, messages, information or materials sent over the Internet may not be completely private, and your anonymity is not guaranteed.</p>
            <p><em><strong>c.</strong></em> <strong><em>Disclaimer about Third Party Links</em></strong>—The Content that you may access while using This Site may contain links to other websites or services, which are provided solely as a convenience to you and the inclusion of any such link does not imply endorsement, investigation or verification by us.&nbsp;</p>
            <p><em><strong>d. General</strong></em><strong><em> Disclaimers</em></strong>—All use of This Site and all Content provided by us is at your own risk.&nbsp; This Site and all Content, to be clear including all products and services provided under these Terms, are provided on an “<strong>AS-IS</strong>” and “<strong>AS-AVAILABLE</strong>” basis.&nbsp; We make <strong><u>no</u></strong> conditions, warranties or representations about the suitability, reliability, usability, security, quality, capacity, performance, availability, timeliness or accuracy of This Site.&nbsp; We expressly disclaim all conditions, warranties and representations, express, implied or statutory, including implied conditions or warranties of merchantability, fitness for a particular purpose, durability, title and non-infringement, whether arising by usage of trade, by course of dealing, by course of performance, at law, in equity, by statute or otherwise howsoever.</p>
            <p><strong><em>e. To the maximum extent allowed, we have no liability to you or any Additional Customers</em></strong>—Notwithstanding any other provision of these Terms, and to the maximum extent permitted by applicable law, in no event will we be liable for any direct, indirect, special, incidental, consequential or punitive damages, or any other expenses, damages or losses whatsoever, including (whether direct or indirect) damages for loss of profits, goodwill, opportunity, earnings, use or data, arising from or related to these Terms, The Site, any Content or software in connection therewith, or an Guided Trip, regardless of the cause of action and even if one or more of us have been advised of the possibility of such damages or losses, or if such damages or losses would be reasonably foreseeable.&nbsp; It is impossible to fully list the extent of this limit of liability, but, to be clear, to the maximum extent permissible under applicable law, we have no liability whatsoever to you for any other expenses, damages or losses arising the following:</p>
            <p style="padding-left: 40px;">(i) Any personal injury (including death) or property damage (including, but not limited to equipment damage) arising from a Guided Trip purchased or acquired through This Site;</p>
            <p style="padding-left: 40px;">(ii) sending, receiving, not sending, not receiving, loss, deletion or alteration of any transmissions, data or transactions entered into through or using This Site;</p>
            <p style="padding-left: 40px;">(iii) any suspension, curtailment, restriction, termination or other limitation placed on your use of the This Site, Your Content, or your Account;</p>
            <p style="padding-left: 40px;">(iv) any act or omission of you or any third party, including any threatening, defamatory, obscene, offensive or illegal conduct or any infringement of another’s rights, including intellectual property rights;</p>
            <p style="padding-left: 40px;">(v) any loss or damage to Your Content or other data arising directly or indirectly from your use or non-use of This Site or related components;</p>
            <p style="padding-left: 40px;">(vi) the performance of the public internet or This Site;</p>
            <p style="padding-left: 40px;">(vii) the content or accuracy of any material, information or data (including any software) related to these Terms or viewed, downloaded, accessed or transmitted using, over or through the internet or This Site, including material that infringes the rights of others or otherwise violates laws or regulations; and</p>
            <p style="padding-left: 40px;">(viii) delays, errors, interruptions, mistakes, omissions, non-delivery, incorrect delivery, viruses, malware, Trojan horses, spyware, spam or defects in the transmission of any information, material or data over or through our systems or networks or the systems or networks of third parties.</p>
            <p><strong><em>f. Exceptions where (and to the extent) prohibited by law</em></strong>—Some jurisdictions prohibit the disclaimer of certain warranties or conditions or the limitation of certain types of liability, such as those we have set out in this “Disclaimers, Limits of Liability, Release of Liability, and Indemnities” section.&nbsp; In such circumstances, to the extent that such prohibitions prohibit any disclaimers, exclusions and limitations in these Terms, such disclaimers, exclusions and limitations will not apply to you strictly to the extent necessary to make these Terms consistent with such prohibitions.</p>
            <p><strong><em>g. </em></strong><strong><em>Release of Liability</em></strong>—In consideration of the services provided to you by Climb Onsight, you and any Additional Customers hereby agree not to sue US for any personal injury (including death) or property damage (including, but not limited to equipment damage), which you may suffer, arising from or in any way connected with the use of This Site, including from a Guided Trip. By accepting these Terms and specifically this agreement not to sue, you and any Additional Customers are releasing any right to make a claim or file a lawsuit against us and you agree to hold us harmless and release us from any and all liability and/or claims or causes of action for injury or death to persons or damage to property arising from my participation in the Guided Trip, INCLUDING, BUT NOT LIMITED TO THOSE CLAIMS BASED ON ANY ALLEGED OR ACTUAL NEGLIGENCE BY US or breach of any contract and/or express or implied warranty.</p>
            <p><strong><em>h. Indemnity by you</em></strong>—We provide This Site on the basis of these Terms, including this “Disclaimers, Limits of Liability, Release of Liability, and Indemnities” section.&nbsp; By using This Site, we will deem you to have agreed to indemnify us from (and disclaim our responsibility for) any actions, proceedings, claims, liabilities, damages and reasonable expenses (including legal expenses) relating to or arising from (i) access to or use, by you or permitted by you, of the This Site or your Account, (ii) any of your acts or omissions, including breach or non-performance of these Terms and any violation of any third party rights, (iii) any event, accident, incident or happening arising out of or in any way connected with a Guided Trip that you booked, including any event, accident, incident, or happening occurring to any Additional Customer.</p>
            <h3>13. General Provisions</h3>
            <p><em><strong>a. </strong></em><strong><em>Interpretation rules for these Terms</em></strong>—In these Terms, (i) we use headings and captions (such as the titles of numbered sections, or the summaries before “—” in each subsection) for convenience only, but they do not form any substantive part of these Terms and should not be used to interpret them, (ii) when we use the word “<strong>including</strong>”, the word “<strong>includes</strong>” and the phrase “<strong>such as</strong>”, or similar phrases, we always mean it to be non-limiting, so it should be read as if it said “without limitation” or “but not limited to” or similar phrases right after it (iii) the word “<strong>or</strong>” between does not imply an exclusive relationship between the matters being connected (so it can always be read as “and/or” unless the context requires), and (iv) all references to website addresses or URLs shall also include any successor or replacement websites containing substantially similar information as the referenced website(s).</p>
            <p><strong><em>b. If we waive any of our rights and remedies, or amend this Agreement, it will only be in writing</em></strong>—Our failure to insist upon or enforce strict performance of any provision of these Terms will not be construed as a waiver of any provision or right. Neither the course of conduct between you and us nor trade practice shall act to modify any provision of these Terms. Our rights, powers and remedies in these Terms, including without limitation the right to suspend, restrict or terminate your access to any portion of This Site, are cumulative and in addition to and not in substitution for any right, power or remedy that may be available to us at law or in equity.</p>
            <p><em><strong>c.</strong></em> <strong><em>If for any reason some part of these Terms do not apply to you, the rest do</em></strong>—If any provision of these Terms is determined to be invalid or unenforceable in whole or in part, such invalidity or unenforceability will attach only to such provision or part thereof and the remaining part of such provision and all other provisions hereof will continue in full force and effect.</p>
            <p><strong style="font-style: italic;">d.</strong> <strong><em>How we will provide notifications to you</em></strong>—Subject to our Privacy Policy, we may provide you with notifications via email, in hard copy, through your Account, or through conspicuous posting of such notice on This Site, as we may determine in our sole discretion.&nbsp; We will always comply with relevant anti-spam legislation when doing so.</p>
            <p><strong><em>e. What the law applies to these Terms, and how you or we would bring a dispute</em></strong>—These Terms and the rights of the parties hereto are governed by, and will at all times be construed in accordance with, the laws in force in the Province of Ontario and the laws of Canada applicable therein, without reference to its conflict of laws principles.&nbsp; You hereby irrevocably consent to the exclusive jurisdiction of the courts of the Province of Ontario in connection with any matter arising out of or in connection with these Terms, without limiting our ability to seek injunctive relief from any court of competent jurisdiction or to enforce an Ontario judgment against you.</p>
            <p><strong><em>f. Transfers of these Terms are permitted by us only</em></strong>—We may at any time assign our rights and obligations under these Terms, in whole or in part, without your consent or advance notice, but we will use reasonable means to notify you. You may not assign these Terms or any of your rights or obligations under them without our prior, written consent. These Terms will inure to the benefit of and bind you and us and our respective personal and legal representatives, successors and permitted assigns.</p>
            <p><em><strong>g.</strong></em> <strong><em>Where necessary, some parts of these Terms will survive even if the Terms are terminated</em></strong>—All provisions of these Terms that, by their meaning or nature, are intended to survive termination or expiry of these Terms shall survive termination or expiration of these Terms.</p>
            <p><strong><em>h. Our relationship with you</em></strong>– You agree that no joint venture, partnership, employment or agency relationship exists between us and you as a result of these Terms or your use of This Site.</p>
            <p><strong><em>i. These Terms are our entire agreement relating to This Site</em></strong><em>—</em>These Terms, as amended from time to time, including any and all documents, websites, rules, terms and policies referenced herein, constitutes the entire agreement between us and you with respect to the matters referred to in these Terms and supersedes all prior and contemporaneous agreements and understandings, whether electronic, oral or written, between us and you with respect to such matters.</p>
            <h3>14. Questions and Concerns:</h3>
            <p>If you have any questions or concerns about This Site or these Terms, please contact us at support@climbonsight.ca.</p>
            <p><!-- /wp:paragraph --></p>						</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions"
}
</script>

<style scoped>
p {
  font-size: 12px;
}

h2 {
  font-size: 16px;
}
</style>